import React, { useEffect, useMemo, useRef } from 'react';
import * as PropTypes from 'prop-types';
import { useFieldFast } from '../../modules/form/hooks';
import { useSelector } from 'react-redux';
import { selectExpenseTypesById } from '../../modules/expenseTypes/expenseTypeSlice';
import { selectBillingTypesById } from '../../modules/billingTypes/billingTypeSlice';

const ExpenseTypeFormWatcher = ({ children }) => {
    const expenseTypesById = useSelector(selectExpenseTypesById);
    const billingTypesById = useSelector(selectBillingTypesById);

    const prevExpenseTypeId = useRef(null);

    const [{ value: expenseTypeId }] = useFieldFast('expense_type_id');
    const [{ value: billingTypeId }, , { setValue: setBillingTypeId }] =
        useFieldFast('billing_type_id');

    const expenseType = useMemo(
        () => expenseTypesById[expenseTypeId],
        [expenseTypesById, expenseTypeId]
    );
    const billingType = useMemo(() => billingTypesById[billingTypeId], [billingTypeId]);

    useEffect(() => {
        if (prevExpenseTypeId.current !== expenseTypeId && expenseType?.billing_type_id) {
            setBillingTypeId(expenseType.billing_type_id);
        }
    }, [expenseType]);

    return children(expenseType, billingType);
};

ExpenseTypeFormWatcher.propTypes = {
    snakeCase: PropTypes.bool,
};

ExpenseTypeFormWatcher.defaultProps = {
    snakeCase: false,
};

export default ExpenseTypeFormWatcher;
