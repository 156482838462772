import { combineDateWithTimeOrNormalize, convertLocalDateToUTC } from '../datetime/utils';
import { isValid } from 'date-fns';

export const INDIVIDUAL = 'individual';

export const formatTaskSubmit = ({
    due_at: dueDate,
    due_time: dueTime,
    task_type_id: taskTypeId,
    task_type_text: taskTypeText,
    ...rest
}) => ({
    ...rest,
    due_at:
        dueDate && convertLocalDateToUTC(combineDateWithTimeOrNormalize(dueDate, dueTime, true)),
    has_due_time: dueDate && isValid(dueTime),
    task_type_id: taskTypeId === INDIVIDUAL ? null : taskTypeId,
    task_type_text: taskTypeId === INDIVIDUAL ? taskTypeText : null,
});
