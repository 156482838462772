import {
    EMPLOYEE_RESOURCE,
    GROUP_RESOURCE,
    RESOURCE_RESOURCE,
    TASK_RESOURCE,
} from '../api/resources';
import { getColor } from '../icons/avatars';
import { union } from 'lodash';
import {
    addDays,
    addMinutes,
    addMonths,
    addWeeks,
    areIntervalsOverlapping,
    differenceInSeconds,
    endOfDay,
    endOfMonth,
    isAfter,
    isSameDay,
    setSeconds,
    startOfDay,
    startOfMonth,
    subDays,
    subMinutes,
    subMonths,
    subWeeks,
} from 'date-fns';
import { firstWeekDay, importDate, lastWeekDay } from '../datetime/utils';

export const CALENDAR_RESOURCE_MODELS = {
    [RESOURCE_RESOURCE]: 'App\\Models\\Resource',
    [EMPLOYEE_RESOURCE]: 'App\\Models\\Employee',
    [GROUP_RESOURCE]: 'App\\Models\\Group',
    [TASK_RESOURCE]: 'App\\Models\\Task',
};

export const CALENDAR_VIEWS = {
    MONTH: 'month',
    WEEK: 'week',
    WORKWEEK: 'workweek',
    DAY: 'day',
};

export const appointmentDefaultHeight = 60;
export const appointmentMinHeight = 50;
export const appointmentAllDayMinHeight = 46;

export const parseFlatAppointmentId = flatId => {
    if (typeof flatId === 'string' && flatId.includes('.')) {
        const [appointmentId, resourceEntityType, resourceEntityId] = flatId.split('.');
        return {
            appointmentId: parseInt(appointmentId),
            resourceEntityId: parseInt(resourceEntityId),
            resourceEntityType,
        };
    }
    if (!Number.isNaN(flatId)) {
        return { appointmentId: parseInt(flatId) };
    }
    return null;
};

export const formatEmployeeForCalendar = employee => {
    if (!employee) {
        return null;
    }

    const { id, person, user } = employee;

    const getDisplayName = () => {
        if (user && user.display_name) {
            return user.display_name;
        }

        if (person && person.display_name) {
            return person.display_name;
        }

        return `Employee ${id}`;
    };

    const getShort = () => {
        if (user && user.display_name_short) {
            return user.display_name_short;
        }

        const displayName = getDisplayName();

        if (displayName) {
            return displayName.slice(0, 3);
        }

        return '';
    };

    const getAvatar = () => {
        if (user && user.avatar) {
            return user.avatar;
        }
    };

    const text = getDisplayName();
    const short = getShort();
    const color = getColor(user?.display_name || user?.display_name_short, user?.color);
    const avatar = getAvatar();

    return {
        id,
        key: `calendarResourcePickerEmployee.${id}`,
        flatKey: formatFlatResourceKey(id, EMPLOYEE_RESOURCE).key,
        text,
        short,
        icon: null,
        avatar,
        color,
    };
};

export const formatResourceForCalendar = resource => {
    if (!resource) {
        return null;
    }

    const { id, display_name, color, icon } = resource;

    return {
        id,
        key: `calendarResourcePickerResource.${id}`,
        flatKey: formatFlatResourceKey(id, RESOURCE_RESOURCE).key,
        text: display_name,
        short: null,
        color: getColor(display_name, color),
        icon,
        avatar: null,
    };
};

export const formatUserForCalender = (user, person) => {
    const { id } = user;

    return formatEmployeeForCalendar({ id, user, person });
};

export const formatGroupForCalendar = group => {
    if (!group) {
        return null;
    }

    const { id, name, color, icon, members } = group;

    const getShort = () => {
        if (name && typeof name === 'string') {
            if (name.length < 3) {
                return name;
            }

            return name.slice(0, 3);
        }
        return '';
    };

    return {
        id,
        key: `calendarResourcePickerGroup.${id}`,
        flatKey: formatFlatResourceKey(id, GROUP_RESOURCE).key,
        text: name,
        short: getShort(),
        color: getColor(name, color),
        icon,
        avatar: null,
        members,
    };
};

export const FLAT_UNPLANNED = { key: 'unplanned', id: 'unplanned', resource: 'unplanned' };

export const formatFlatResourceKey = (resourceId, resourceType) => ({
    key: `${resourceType}.${resourceId}`,
    id: resourceId,
    resource: resourceType,
});

export const formatFlatResources = (appointment, resourceMatchConfig) => {
    const { resourceMatches } = replenishResourceMatches(appointment, resourceMatchConfig);

    return resourceMatches
        ? Object.entries(resourceMatches).reduce((carry, [model, ids]) => {
              const resourceKeys = Object.entries(CALENDAR_RESOURCE_MODELS).reduce(
                  (carry, [findKey, findModel]) => (findModel === model ? findKey : carry),
                  null
              );

              return [...carry, ...ids.map(id => formatFlatResourceKey(id, resourceKeys).key)];
          }, [])
        : [];
};

export const formatFlatResourceMeta = appointment => {
    const { resourceMatches, plannedAt } = appointment;

    const flatMeta = resourceMatches
        ? Object.entries(resourceMatches).reduce((carry, [model, ids]) => {
              const resourceKeys = Object.entries(CALENDAR_RESOURCE_MODELS).reduce(
                  (carry, [findKey, findModel]) => (findModel === model ? findKey : carry),
                  null
              );

              return [
                  ...carry,
                  ...ids.reduce((carryIds, id) => {
                      const newKey = formatFlatResourceKey(id, resourceKeys);

                      if (
                          carryIds.find(({ key: carryKey }) => carryKey === newKey.key) ||
                          carry.find(({ key: carryKey }) => carryKey === newKey.key)
                      ) {
                          return carryIds;
                      }

                      return [...carryIds, newKey];
                  }, []),
              ];
          }, [])
        : [];

    return {
        ...appointment,
        flatMeta: plannedAt ? flatMeta : [FLAT_UNPLANNED, ...flatMeta],
    };
};

export const formatSwapFromFlat = (resource, includeKey = false) => {
    if (resource && resource.includes('.')) {
        const [key, id] = resource.split('.');

        if (key in CALENDAR_RESOURCE_MODELS) {
            return {
                id: Number.parseInt(id),
                type: CALENDAR_RESOURCE_MODELS[key],
                ...(includeKey ? { key } : {}),
            };
        }
    }

    return null;
};

export const getFlatResourceSwap = (original = [], changed = []) => {
    const added = changed.filter(changedId => !original.includes(changedId));
    const removed = original.filter(originalId => !changed.includes(originalId));

    return added.map((key, index) => ({
        from: removed.length > 0 && removed[index] ? formatSwapFromFlat(removed[index]) : null,
        to: formatSwapFromFlat(key),
    }));
};

export const getResourceMatchConfig = appointmentTypes => {
    return appointmentTypes.reduce(
        (carry, { resource_config: configs }) => {
            if (configs && Array.isArray(configs)) {
                const appointmentResourceTypes = configs.reduce(
                    (carry, { api, group }) => {
                        if (api === EMPLOYEE_RESOURCE) {
                            return {
                                [EMPLOYEE_RESOURCE]: [...carry[EMPLOYEE_RESOURCE], group],
                                [RESOURCE_RESOURCE]: carry[RESOURCE_RESOURCE],
                            };
                        }

                        if (api === RESOURCE_RESOURCE) {
                            return {
                                [EMPLOYEE_RESOURCE]: carry[EMPLOYEE_RESOURCE],
                                [RESOURCE_RESOURCE]: [...carry[RESOURCE_RESOURCE], group],
                            };
                        }

                        return carry;
                    },
                    {
                        [EMPLOYEE_RESOURCE]: [],
                        [RESOURCE_RESOURCE]: [],
                    }
                );

                return {
                    [EMPLOYEE_RESOURCE]: union(
                        carry[EMPLOYEE_RESOURCE],
                        appointmentResourceTypes[EMPLOYEE_RESOURCE]
                    ),
                    [RESOURCE_RESOURCE]: union(
                        carry[RESOURCE_RESOURCE],
                        appointmentResourceTypes[RESOURCE_RESOURCE]
                    ),
                };
            }

            return carry;
        },
        {
            [EMPLOYEE_RESOURCE]: [],
            [RESOURCE_RESOURCE]: [],
        }
    );
};

export const replenishResourceMatches = (appointment, resourceKeysByResource) => {
    if (appointment && appointment.baseGroups && appointment.resourceMatches) {
        const { baseGroups, resourceMatches } = appointment;
        const employeeModel = CALENDAR_RESOURCE_MODELS[EMPLOYEE_RESOURCE];
        const resourceModel = CALENDAR_RESOURCE_MODELS[RESOURCE_RESOURCE];

        const { replenishedEmployees, replenishedResources } = Object.entries(baseGroups).reduce(
            (carry, [group, resourceIds]) => {
                const ids = Array.isArray(resourceIds) ? resourceIds : [resourceIds];
                return {
                    replenishedEmployees:
                        EMPLOYEE_RESOURCE in resourceKeysByResource &&
                        resourceKeysByResource[EMPLOYEE_RESOURCE].includes(group)
                            ? [...carry.replenishedEmployees, ...ids]
                            : carry.replenishedEmployees,
                    replenishedResources:
                        RESOURCE_RESOURCE in resourceKeysByResource &&
                        resourceKeysByResource[RESOURCE_RESOURCE].includes(group)
                            ? [...carry.replenishedResources, ...ids]
                            : carry.replenishedResources,
                };
            },
            {
                replenishedEmployees: [],
                replenishedResources: [],
            }
        );

        if (resourceKeysByResource) {
            return {
                ...appointment,
                resourceMatches: {
                    [employeeModel]: union(
                        employeeModel in resourceMatches ? resourceMatches[employeeModel] : [],
                        replenishedEmployees
                    ),
                    [resourceModel]: union(
                        resourceModel in resourceMatches ? resourceMatches[resourceModel] : [],
                        replenishedResources
                    ),
                },
            };
        }
    }

    return appointment;
};

export const getResourceKeyFromModel = model => {
    const keyModelPair = Object.entries(CALENDAR_RESOURCE_MODELS).find(
        ([_, listModel]) => model === listModel
    );

    return keyModelPair ? keyModelPair[0] : null;
};

export const autoAssignResource = (resourceId, resourceType, appointmentType) => {
    if (appointmentType && 'resource_config' in appointmentType) {
        const { resource_config: resourceConfig } = appointmentType;

        if (Array.isArray(resourceConfig)) {
            const matchedGroup = resourceConfig.find(
                groupConfig =>
                    groupConfig && groupConfig.type === resourceType && groupConfig.default
            );

            if (matchedGroup?.group) {
                return matchedGroup.group;
            }
        }
    }

    return null;
};

export const getAppointmentTypeIdsByOverlapType = (appointmentTypes = []) =>
    appointmentTypes.reduce(
        (carry, appointmentType) => {
            if (appointmentType.overlap === 'deny') {
                return {
                    ...carry,
                    denyIds: [...carry.denyIds, appointmentType.id],
                };
            }

            if (appointmentType.overlap === 'warn') {
                return {
                    ...carry,
                    warnIds: [...carry.warnIds, appointmentType.id],
                };
            }

            return carry;
        },
        { denyIds: [], warnIds: [] }
    );

export const checkOverlap = (
    startDate,
    endDate,
    deniedPeriods,
    targetFlatKey = null,
    onlyAllDay = false,
    excludedAppointmentId = null
) =>
    deniedPeriods.find(({ start, end, allDay, resources, appointmentId }) => {
        if (
            excludedAppointmentId !== appointmentId &&
            (!onlyAllDay || allDay) &&
            start &&
            end &&
            startDate &&
            endDate &&
            areIntervalsOverlapping({ start: startDate, end: endDate }, { start, end })
        ) {
            if (targetFlatKey) {
                return (
                    Array.isArray(resources) &&
                    resources.length > 0 &&
                    resources.includes(targetFlatKey)
                );
            }

            return true;
        }

        return false;
    });

export const checkGeneralOverlap = (changedAppointment, appointments = []) => {
    const { id, startDate: start, endDate: end, flatResources, baseResources } = changedAppointment;
    const changedResources = union(flatResources || [], baseResources || []);

    return (
        appointments.findIndex(({ originalId, startDate, endDate, baseResources }) => {
            if (id === originalId) {
                return false;
            }

            if (
                baseResources.findIndex(resourceKey => changedResources.includes(resourceKey)) ===
                -1
            ) {
                return false;
            }

            return areIntervalsOverlapping(
                { start: importDate(startDate), end: importDate(endDate) },
                { start, end }
            );
        }) !== -1
    );
};

export const calcDateRange = (currentView, currentDate, incloseFactor = 0) => {
    if (currentView === CALENDAR_VIEWS.DAY) {
        return {
            start: startOfDay(subDays(currentDate, incloseFactor)),
            end: endOfDay(addDays(currentDate, incloseFactor)),
        };
    }
    if (currentView === CALENDAR_VIEWS.WORKWEEK || currentView === CALENDAR_VIEWS.WEEK) {
        return {
            start: subWeeks(firstWeekDay(currentDate), incloseFactor),
            end: addWeeks(lastWeekDay(currentDate), incloseFactor),
        };
    }
    if (currentView === CALENDAR_VIEWS.MONTH) {
        return {
            start: firstWeekDay(subMonths(startOfMonth(currentDate), incloseFactor)),
            end: lastWeekDay(addMonths(endOfMonth(currentDate), incloseFactor)),
        };
    }
};

export const calcMissingDateRange = (targetRange, knownRange = null, overrideKnown = false) => {
    if (!overrideKnown && knownRange?.start && knownRange?.end) {
        const knownStart = knownRange.start;
        const knownEnd = setSeconds(addMinutes(knownRange.end, 1), 0);

        if (
            knownStart instanceof Date &&
            knownEnd instanceof Date &&
            typeof targetRange === 'object'
        ) {
            const targetStart = targetRange.start;
            const targetEnd = setSeconds(addMinutes(targetRange.end, 1), 0);

            if (targetStart instanceof Date && targetEnd instanceof Date) {
                const isStartEqual = isSameDay(targetStart, knownStart);
                const isEndEqual = isSameDay(targetEnd, knownEnd);

                if (isStartEqual && isEndEqual) {
                    return {
                        targetRange,
                        knownRange,
                        changed: false,
                        reset: false,
                    };
                }

                const isOverlapping = areIntervalsOverlapping(targetRange, knownRange);
                const isStartInKnown = isStartEqual || isAfter(targetStart, knownStart);
                const isEndInKnown = isEndEqual || isAfter(knownEnd, targetEnd);

                if (isOverlapping && isStartInKnown && isEndInKnown) {
                    return {
                        targetRange,
                        knownRange,
                        changed: false,
                        reset: false,
                    };
                }

                const dayInSeconds = 60 * 60 * 24;
                const isTargetStartBounding =
                    Math.abs(differenceInSeconds(targetStart, knownEnd)) <= dayInSeconds;
                const isTargetEndBounding =
                    Math.abs(differenceInSeconds(targetEnd, knownStart)) <= dayInSeconds;

                if (isOverlapping || isTargetStartBounding || isTargetEndBounding) {
                    const minStart = isAfter(targetStart, knownStart) ? knownStart : targetStart;
                    const maxEnd = isAfter(targetEnd, knownEnd) ? targetEnd : knownEnd;
                    const missingStart = isAfter(targetStart, knownStart) ? knownEnd : targetStart;
                    const missingEnd = isAfter(targetEnd, knownEnd) ? targetEnd : knownStart;

                    return {
                        targetRange: {
                            start: missingStart,
                            end: setSeconds(subMinutes(missingEnd, 1), 59),
                        },
                        knownRange: {
                            start: minStart,
                            end: setSeconds(subMinutes(maxEnd, 1), 59),
                        },
                        changed: true,
                        reset: false,
                    };
                }
            }
        }
    }

    return {
        targetRange,
        knownRange: targetRange,
        changed: true,
        reset: true,
    };
};

export const calcAgendaCellHeight = appointmentMaxCount => {
    const multiHeight = appointmentMaxCount * appointmentMinHeight;

    return multiHeight > appointmentDefaultHeight ? multiHeight : appointmentDefaultHeight;
};

export const buildStripedBackground = (base, stripes) =>
    `linear-gradient(45deg, ${base} 25%, ${stripes} 25%, ${stripes} 50%, ${base} 50%, ${base} 75%, ${stripes} 75%, ${stripes} 100%)`;
