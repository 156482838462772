import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { IdPropType } from '../../modules/proptypes';
import { useSelector } from 'react-redux';
import { selectAppointmentById } from '../../modules/appointments/appointmentSlice';
import { formatAppointmentImport, restructureDates } from '../../modules/appointments/utils';
import { useTranslation } from 'react-i18next';
import CalendarDetailChange from '../calendar/detail/CalendarDetailChange';
import { formatLocalDateTime, importDate } from '../../modules/datetime/utils';
import { CHANGES } from '../calendar/CalendarDetail';
import CalendarDetailResourcesText from '../calendar/detail/CalendarDetailResourcesText';
import CardContactsPrintView from '../contacts/CardContactsPrintView';
import { selectPriorityById } from '../../modules/priority/prioritySlice';
import { selectCategoryById } from '../../modules/categories/selectors';
import { useAppointmentName } from '../../modules/appointments/hooks';
import { capitalizeFirstLetter } from '../../modules/text/utils';

const titleColStyle = { width: 180, verticalAlign: 'top' };

const AppointmentPrintView = ({ appointmentId }) => {
    const { t } = useTranslation();

    const originalAppointment = useSelector(state => selectAppointmentById(state, appointmentId));

    const {
        title,
        notes,
        address,

        startDate,
        endDate,

        appointmentTypeId,
        absenceTypeId,
        categoryId,
        priorityId,

        card,
        task,
        grouped,

        plannedAt,
        plannedBy,
        completedAt,
        completedBy,
        createdAt,
        createdBy,
        updatedAt,
        updatedBy,
    } = useMemo(
        () => (originalAppointment ? formatAppointmentImport(originalAppointment) : {}),
        [originalAppointment]
    );

    const finalPriorityId = priorityId || task?.priorityId;
    const finalCategoryId = categoryId || task?.categoryId;

    const priority = useSelector(state => selectPriorityById(state, finalPriorityId));
    const category = useSelector(state => selectCategoryById(state, finalCategoryId));

    const titleText = useAppointmentName({ title, task, appointmentTypeId, absenceTypeId });
    const [dateMainPhrase, dateOptionalPhrase] =
        startDate && endDate ? restructureDates(startDate, endDate) : null;

    const addressText = useMemo(() => (address || card?.address)?.short, [address, card]);

    const formatChangeText = (type, displayName, dateText, date) => {
        const typeKey = Object.values(CHANGES).includes(type) ? type : 'change';
        const actionText = t(`components.CalendarDetail.changes.${typeKey}`);

        return `${capitalizeFirstLetter(actionText)} ${t(
            'components.CalendarDetail.changes.by'
        )} ${displayName} ${t('components.CalendarDetail.changes.on')} ${
            date ? formatLocalDateTime(importDate(date)) : null
        }`;
    };

    if (!originalAppointment) {
        return null;
    }

    return (
        <div>
            <h2>{titleText}</h2>
            <h3>
                {dateMainPhrase}
                {dateOptionalPhrase ? ` - ${dateOptionalPhrase}` : null}
            </h3>
            <hr />
            {card.id ? (
                <table>
                    <tr>
                        <td style={titleColStyle}>{t('components.AppointmentPrintView.card')}</td>
                        <td>{card?.title}</td>
                    </tr>
                    {card?.idOffer ? (
                        <tr>
                            <td style={titleColStyle}>
                                {t('components.AppointmentPrintView.idOffer')}
                            </td>
                            <td>{card?.idOffer}</td>
                        </tr>
                    ) : null}
                    {card?.idOrder ? (
                        <tr>
                            <td style={titleColStyle}>
                                {t('components.AppointmentPrintView.idOrder')}
                            </td>
                            <td>{card?.idOrder}</td>
                        </tr>
                    ) : null}
                </table>
            ) : null}
            {card.id && task.id ? <br /> : null}
            {task.id ? (
                <table>
                    <tr>
                        <td style={titleColStyle}>{t('components.AppointmentPrintView.task')}</td>
                        <td>{task.name}</td>
                    </tr>
                    {priority ? (
                        <tr>
                            <td style={titleColStyle}>
                                {t('components.AppointmentPrintView.priority')}
                            </td>
                            <td>{priority.name}</td>
                        </tr>
                    ) : null}
                    {category ? (
                        <tr>
                            <td style={titleColStyle}>
                                {t('components.AppointmentPrintView.category')}
                            </td>
                            <td>{category.name}</td>
                        </tr>
                    ) : null}
                    <tr>
                        <td style={titleColStyle}>
                            {t('components.AppointmentPrintView.location')}
                        </td>
                        <td>{addressText}</td>
                    </tr>
                </table>
            ) : null}
            <br />
            {card.id && task.id ? <hr /> : null}

            {card?.id ? (
                <>
                    <CardContactsPrintView cardId={card.id} />
                    <hr />
                </>
            ) : null}

            {notes ? (
                <>
                    <p>{notes}</p>
                    <hr />
                </>
            ) : null}

            <p>
                <CalendarDetailChange
                    date={createdAt}
                    userId={createdBy}
                    textOnly
                    onFormatText={(displayName, dateText) =>
                        formatChangeText(CHANGES.CREATE, displayName, dateText, createdAt)
                    }
                />
            </p>
            {updatedAt && updatedBy ? (
                <p>
                    <CalendarDetailChange
                        date={updatedAt}
                        userId={updatedBy}
                        textOnly
                        onFormatText={(displayName, dateText) =>
                            formatChangeText(CHANGES.UPDATE, displayName, dateText, updatedAt)
                        }
                    />
                </p>
            ) : null}
            {plannedAt && plannedBy ? (
                <p>
                    <CalendarDetailChange
                        date={plannedAt}
                        userId={plannedBy}
                        textOnly
                        onFormatText={(displayName, dateText) =>
                            formatChangeText(CHANGES.PLAN, displayName, dateText, plannedAt)
                        }
                    />
                </p>
            ) : null}
            {completedAt && completedBy ? (
                <p>
                    <CalendarDetailChange
                        date={completedAt}
                        userId={completedBy}
                        textOnly
                        onFormatText={(displayName, dateText) =>
                            formatChangeText(CHANGES.DONE, displayName, dateText, completedAt)
                        }
                    />
                </p>
            ) : null}
            <br />
            {grouped ? (
                <CalendarDetailResourcesText
                    appointmentTypeId={appointmentTypeId}
                    groupedIds={grouped}
                />
            ) : null}
        </div>
    );
};

AppointmentPrintView.propTypes = {
    appointmentId: IdPropType.isRequired,
};

export default AppointmentPrintView;
