import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Select from '../form/formik/Select';
import {
    selectAllBillingTypes,
    useBillingTypes,
} from '../../modules/billingTypes/billingTypeSlice';

const BillingTypeSelect = ({ name, ...other }) => {
    const { loading } = useBillingTypes();
    const billingTypes = useSelector(selectAllBillingTypes);

    const options = useMemo(() => {
        if (Array.isArray(billingTypes) && billingTypes.length) {
            return billingTypes.map(({ id, name }) => ({ label: name, value: id }));
        }

        return [];
    }, [billingTypes]);

    return <Select name={name} options={options} {...other} />;
};

BillingTypeSelect.propTypes = {};

export default BillingTypeSelect;
