import { createResourceSlice } from '../redux/resource/createResourceSlice';
import { EXPENSE_RESOURCE } from '../api/resources';

const expenseSlice = createResourceSlice({
    resource: EXPENSE_RESOURCE,
    byKey: '',
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
    indexExpenses,
    showExpense,
    storeExpense,
    updateExpense,
    archiveExpense,
    restoreExpense,
    destroyExpense,
} = expenseSlice.actions;

export const {
    selectExpenseById,
    makeExpensesByIdsSelector,
    selectAllExpenseIds,
    selectAllExpenses,
    selectExpensesLoading,
    selectExpensesInitialized,
    selectExpenseLoading,
    selectExpenseInitialized,
    selectExpenseIdsBy,
    makeExpensesBySelector,
    selectExpenseLoadingBy,
    selectExpenseInitializedBy,
} = expenseSlice.selectors;

export const { useExpenses, useExpense, useExpensesBy } = expenseSlice.hooks;

export default expenseSlice.reducer;
