import React, { useCallback, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { IdPropType } from '../../../modules/proptypes';
import JournalTable from '../../journal/JournalTable';
import { useSelector } from 'react-redux';
import { selectEmployeeById } from '../../../modules/employees/employeesSlice';
import WorkTimeSummary from '../../worktimeSummary/WorkTimeSummary';

const EmployeeJournal = ({ employeeId }) => {
    const [searchFilters, setSearchFilters] = useState(null);
    const employee = useSelector(state => selectEmployeeById(state, employeeId));
    const referenceId = useMemo(
        () => ({ key: 'user_id', value: employee?.user_id }),
        [employee?.user_id]
    );

    const summaryFetchParams = useMemo(
        () =>
            searchFilters
                ? {
                      employee_id: employeeId,
                      ...searchFilters,
                  }
                : null,
        [employee, searchFilters]
    );

    const handleSearch = useCallback(groupedFilters => {
        setSearchFilters(groupedFilters);
    }, []);

    return (
        <JournalTable
            listId={`journals.employees.${employeeId}`}
            referenceId={referenceId}
            onSearch={handleSearch}
            bottomToolbarCustomActions={<WorkTimeSummary fetchParams={summaryFetchParams} />}
        />
    );
};

EmployeeJournal.propTypes = {
    employeeId: IdPropType.isRequired,
};

export default EmployeeJournal;
