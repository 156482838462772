import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { IdPropType } from '../../../modules/proptypes';
import {
    indexProtocols,
    updateProtocol,
    useProtocolsByCardId,
} from '../../../modules/protocol/protocolsSlice';
import ProtocolTab from './ProtocolTab';
import { useCallbackFunc } from '../../../modules/hooks';
import DialogControlContext from '../../dialogs/DialogControlContext';
import ProtocolDialog from '../../../modules/protocol/components/ProtocolDialog';
import ProtocolTabActions from './ProtocolTabActions';
import {
    AddLink,
    AutoStories,
    CalendarMonth,
    ContactPhone,
    Feed,
    FolderCopy,
    FormatListNumbered,
    ManageSearch,
    TravelExplore,
    Update,
    EuroSymbol,
} from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
    },
    tabs: {
        height: '100%',
    },
    tab: {
        minHeight: 48,
        justifyContent: 'start',
        alignItems: 'center',
    },

    indicator: {
        backgroundColor: theme.palette.primary.main,
        left: 0,
        width: 5,
    },

    scrollButtons: {
        height: 24,
    },

    indentation: {
        paddingLeft: '48px', //theme.spacing(4),
    },
}));

const MIN_PROTOCOLS = 6;

const getOrder = (positionTo, repositioned, prev) => {
    // subsequent item;
    if (prev) {
        return prev.order + 1;
    }

    return 1;
};

const sortProtocols = (protocols, showAllProtocols) => {
    const sorted = protocols ? [...protocols] : [];
    sorted.sort((p1, p2) => p1.order - p2.order);
    return showAllProtocols ? sorted : sorted.splice(0, MIN_PROTOCOLS);
};

const CardNav = ({ active, onChange, disabled, cardId }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [showAllProtocols, setShowAllProtocols] = useState(false);

    const [protocols] = useProtocolsByCardId(cardId, { forceOnce: true });
    // TODO: show visual indicator
    const [locked, setLocked] = useState(false);

    const [orderedProtocols, setOrderedProtocols] = useState([]);
    useEffect(() => {
        if (!locked) {
            setOrderedProtocols(sortProtocols(protocols, showAllProtocols));
        }
    }, [locked, protocols, showAllProtocols]);

    const handleDrop = useCallbackFunc(result => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const positionFrom = result.source.index;
        const positionTo = result.destination.index;

        // no need to change anything
        if (positionFrom === positionTo) {
            return;
        }

        // reorder item
        const updated = Array.from(orderedProtocols);
        const [repositioned] = updated.splice(positionFrom, 1);
        updated.splice(positionTo, 0, repositioned);

        setOrderedProtocols(updated);

        const prev = updated[positionTo - 1];

        const order = getOrder(positionTo, repositioned, prev);

        setLocked(true);
        dispatch(
            updateProtocol({
                scope: repositioned.protocol_template_id ? 'global' : 'local',
                id: repositioned.id,
                card_id: repositioned.card_id,
                order,
            })
        )
            .then(() => {
                return dispatch(
                    indexProtocols(
                        { card_id: repositioned.card_id },
                        { initialize: { byKey: 'cardId', keyId: repositioned.card_id } }
                    )
                );
            })
            .then(() => {
                setLocked(false);
            });
    });

    // TODO: use our default dialog handling when cards have their own page
    const [open, setOpen] = useState(false);
    const [protocolId, setProtocolId] = useState(null);
    const dialogProps = useMemo(
        () => ({
            open,
            onClose: () => setOpen(false),
            setConfirmClose: () => null,
        }),
        [open, setOpen]
    );
    const openProtocolDialog = useCallback(
        id => {
            setProtocolId(id);
            setOpen(true);
        },
        [setOpen, setProtocolId]
    );

    const handleChange = (event, value) => {
        if (value !== 'more') {
            onChange(event, value);
        }
    };

    return (
        <DragDropContext onDragEnd={handleDrop}>
            <DialogControlContext.Provider value={dialogProps}>
                <ProtocolDialog
                    cardId={cardId}
                    protocolId={protocolId}
                    onClose={dialogProps.onClose}
                />
            </DialogControlContext.Provider>

            <Droppable droppableId="CardNav" type="CardNavItem" isDropDisabled={locked}>
                {provided => (
                    <Box
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className={classes.root}
                    >
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={disabled ? false : active}
                            onChange={handleChange}
                            textColor="primary"
                            aria-label="Card navigation"
                            className={classes.tabs}
                            classes={{
                                indicator: classes.indicator,
                                scrollButtons: classes.scrollButtons,
                            }}
                        >
                            <Tab
                                label={t('navigation.card.overview')}
                                id="card-nav-overview"
                                aria-controls="card-panel-overview"
                                value="overview"
                                disabled={disabled}
                                icon={<ManageSearch fontSize="small" />}
                                iconPosition="start"
                                className={classes.tab}
                            />
                            <Tab
                                label={t('navigation.card.contacts')}
                                id="card-nav-contacts"
                                aria-controls="card-panel-contacts"
                                value="contacts"
                                disabled={disabled}
                                icon={<ContactPhone fontSize="small" />}
                                iconPosition="start"
                                className={classes.tab}
                            />
                            <Tab
                                label={t('navigation.card.protocol')}
                                id="card-nav-protocol"
                                aria-controls="card-panel-protocol"
                                value="protocol"
                                disabled={disabled}
                                onCreate={openProtocolDialog}
                                component={ProtocolTabActions}
                                icon={<Feed fontSize="small" />}
                                iconPosition="start"
                                className={classes.tab}
                            />
                            {orderedProtocols.map((protocol, index) => (
                                <Tab
                                    key={protocol.id}
                                    protocolId={protocol.id}
                                    onEdit={openProtocolDialog}
                                    index={index}
                                    label={protocol.name}
                                    id={`card-nav-protocol-${protocol.id}`}
                                    aria-controls={`card-panel-protocol-${protocol.id}`}
                                    value={`card-protocol-${protocol.id}`}
                                    disabled={disabled}
                                    className={classes.indentation}
                                    placeholder={
                                        index === protocols.length - 1 ? provided.placeholder : null
                                    }
                                    component={ProtocolTab}
                                />
                            ))}
                            {!showAllProtocols && protocols.length > MIN_PROTOCOLS && (
                                <Tab
                                    label={<MoreHorizIcon fontSize="small" />}
                                    id="card-nav-more"
                                    aria-controls="card-panel-more"
                                    value="more"
                                    className={classes.indentation}
                                    onClick={() => setShowAllProtocols(true)}
                                    placeholder={6}
                                />
                            )}
                            <Tab
                                label={t('navigation.card.calendar')}
                                id="card-nav-calendar"
                                aria-controls="card-panel-calendar"
                                value="calendar"
                                disabled={disabled}
                                icon={<CalendarMonth fontSize="small" />}
                                iconPosition="start"
                                className={classes.tab}
                            />
                            <Tab
                                label={t('navigation.card.tasks')}
                                id="card-nav-tasks"
                                aria-controls="card-panel-tasks"
                                value="tasks"
                                disabled={disabled}
                                icon={<FormatListNumbered fontSize="small" />}
                                iconPosition="start"
                                className={classes.tab}
                            />
                            <Tab
                                label={t('navigation.card.journal')}
                                id="card-nav-journal"
                                aria-controls="card-panel-journal"
                                value="journal"
                                disabled={disabled}
                                icon={<Update fontSize="small" />}
                                iconPosition="start"
                                className={classes.tab}
                            />
                            <Tab
                                label={t('navigation.card.expenses')}
                                id="card-nav-expenses"
                                aria-controls="card-panel-expenses"
                                value="expenses"
                                disabled={disabled}
                                icon={<EuroSymbol fontSize="small" />}
                                iconPosition="start"
                                className={classes.tab}
                            />
                            <Tab
                                label={t('navigation.card.files')}
                                id="card-nav-files"
                                aria-controls="card-panel-files"
                                value="files"
                                disabled={disabled}
                                icon={<FolderCopy fontSize="small" />}
                                iconPosition="start"
                                className={classes.tab}
                            />
                            <Tab
                                label={t('navigation.card.map')}
                                id="card-nav-map"
                                aria-controls="card-panel-map"
                                value="map"
                                disabled={disabled}
                                icon={<TravelExplore fontSize="small" />}
                                iconPosition="start"
                                className={classes.tab}
                            />
                            <Tab
                                label={t('navigation.card.cardlinks')}
                                id="card-nav-cardlinks"
                                aria-controls="card-panel-cardlinks"
                                value="cardlinks"
                                disabled={disabled}
                                icon={<AddLink fontSize="small" />}
                                iconPosition="start"
                                className={classes.tab}
                            />
                            <Tab
                                label={t('navigation.card.audits')}
                                id="card-nav-audits"
                                aria-controls="card-panel-audits"
                                value="audits"
                                disabled={disabled}
                                icon={<AutoStories fontSize="small" />}
                                iconPosition="start"
                                className={classes.tab}
                            />
                        </Tabs>
                    </Box>
                )}
            </Droppable>
        </DragDropContext>
    );
};

CardNav.propTypes = {
    cardId: IdPropType,
    active: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

CardNav.defaultProps = {
    cardId: null,
    disabled: false,
};

export default memo(CardNav);
