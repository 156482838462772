export const importWorktimeSummary = ({
    external,
    internal,
    not_order_related: notOrderRelated,
    leave,
    special_leave: specialLeave,
    holiday,
    //saturday,
    sick,
    total,
    target,
    diff,
    night_shift: nightShift,
}) => ({
    external,
    internal,
    notOrderRelated,
    leave,
    specialLeave,
    holiday,
    sick,
    total,
    target,
    diff,
    nightShift,
});
