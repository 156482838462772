import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import Box from '@mui/material/Box';
import { Autocomplete, Paper } from '@mui/material';
import AsyncAutocompleteInput from './AsyncAutocompleteInput';
import { RefPropType } from '../../../../modules/proptypes';
import { useContextualCan } from '../../../../modules/abilities/hooks';
import { WRITE } from '../../../../modules/abilities/actions';
import VirtualizedListFixedHeight from '../../../layout/VirtualizedListFixedHeight';

const useStyles = makeStyles(theme => ({
    fixedWidth: {
        width: 300,
    },

    contrast: {
        color: 'white',
    },

    popup: {
        position: 'relative',
    },

    popupMedium: {
        minWidth: 600,
    },

    popupSmall: {
        minWidth: 300,
    },

    option: {
        width: '90%',
        padding: `${theme.spacing(1.5, 0)} !important`,
        margin: 'auto',

        '&:not(:last-child)': {
            borderBottom: `1px solid ${theme.palette.grey['200']}`,
        },
    },

    clearIndicator: {
        marginRight: 30,
    },

    extraButton: {
        position: 'absolute',
        right: 35,
    },

    withExtraAdornment: {
        '&[class*="MuiOutlinedInput-root"]': {
            '.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon &': {
                paddingRight: 92,
            },
        },
    },

    footer: {
        padding: theme.spacing(1),
        background: theme.palette.background.default,
        textAlign: 'center',
    },
}));

const AsyncAutocomplete = ({
    value,
    initialValue,
    name,
    originalName,
    onChange,
    label,
    variant,
    threshold,
    fetch,
    filterOptions,
    filterSelectedOptions,
    renderOption,
    renderTags,
    isOptionEqualToValue,
    getOptionLabel,
    innerRef,
    inputRef,
    contrast,
    fullWidth,
    InputProps,
    InputLabelProps,
    disabled,
    classes,
    className,
    freeSolo,
    open: controlledOpen,
    onOpen,
    onClose,
    clearOnBlur,
    blurOnSelect,
    disableCloseOnSelect,
    getOptionDisabled,
    multiple,
    extraButton,
    footer,
    popupSize,
    virtualized,
    inputValue: controlledInputValue,
    onInputChange: onControlledInputChange,
    error,
    handleError,
    errors,
    noOptionsText,
    autofocus,
    autofill,
    can,
    disablePortal,
    clearText,
    autoHighlight,
    ...other
}) => {
    const _classes = useStyles();
    const [uncontrolledInputValue, setUncontrolledInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const loader = useRef(null);
    const textInputRef = useRef(null);
    const { t } = useTranslation();
    const canDo = useContextualCan(can ?? WRITE, originalName || name);
    const [uncontrolledOpen, setOpen] = useState(controlledOpen || false);
    const open = controlledOpen !== undefined ? controlledOpen : uncontrolledOpen;

    useEffect(() => {
        const currentRef = inputRef?.current || textInputRef.current;
        if (autofocus && currentRef) {
            currentRef.focus();
        }
    }, [autofocus]);

    const innerClasses = useMemo(
        () => ({
            clearIndicator: classNames({
                [_classes.contrast]: contrast,
                [_classes.clearIndicator]: extraButton,
            }),
            popupIndicator: classNames({ [_classes.contrast]: contrast }),
            inputRoot: extraButton ? _classes.withExtraAdornment : null,
            paper: classNames(_classes.popup, {
                [_classes.popupMedium]: popupSize === 'medium',
                [_classes.popupSmall]: popupSize === 'small',
            }),
            option: _classes.option,
            ...classes,
        }),
        [_classes, extraButton, classes, popupSize, contrast]
    );

    const inputValue =
        controlledInputValue !== undefined ? controlledInputValue : uncontrolledInputValue;
    const handleChange = useCallback(
        onControlledInputChange ||
            ((event, newValue) => {
                setUncontrolledInputValue(newValue);

                if (freeSolo) {
                    onChange(event, { name: newValue });
                }
            }),
        [onControlledInputChange, setUncontrolledInputValue, freeSolo]
    );

    const fetchThrottled = useMemo(
        () => throttle((query, _onFetched) => Promise.resolve(fetch(query)).then(_onFetched), 800),
        [fetch]
    );

    const fetchSequential = useCallback(
        query => {
            if (loader.current !== query) {
                loader.current = query;
                setLoading(true);

                fetchThrottled(query, _options => {
                    if (loader.current === query) {
                        loader.current = null;
                        setLoading(false);
                        setOptions(_options);
                    }
                });
            }
        },
        [fetchThrottled]
    );

    useEffect(() => {
        if ((inputValue && inputValue.length < threshold) || !open) {
            setOptions([]);
            return undefined;
        }

        fetchSequential(inputValue);

        return () => {
            if (loader.current === inputValue) {
                loader.current = null;
                setLoading(false);
            }
        };
    }, [inputValue, threshold, fetchSequential, open]);

    const handleOpen = useCallback(
        event => {
            /** load initial options if no threshold is set */
            if (threshold === 0 && inputValue === '' && options.length === 0) {
                fetchSequential('');
            }
            setOpen(true);
            if (onOpen) {
                onOpen(event);
            }
        },
        [threshold, inputValue, options, fetchSequential, setOpen, onOpen]
    );

    const handleClose = useCallback(
        (event, reason) => {
            setOpen(false);
            if (onClose) {
                onClose(event, reason);
            }
        },
        [setOpen, onClose]
    );

    const extendedOptions = useMemo(() => {
        const newOptions = (Array.isArray(options) ? options : []).map((option, index) => ({
            ...option,
            showAdditional: options.length - 1 === index,
        }));

        if ((multiple && !value.length) || (!multiple && !value)) {
            return newOptions;
        }

        if (multiple) {
            return filterSelectedOptions
                ? [...value, ...newOptions]
                : [
                      ...value.filter(
                          selected =>
                              !newOptions.find(option => isOptionEqualToValue(option, selected))
                      ),
                      ...newOptions,
                  ];
        }

        if (!newOptions.find(option => isOptionEqualToValue(option, value))) {
            return [value, ...newOptions];
        }

        return newOptions;
    }, [multiple, options, value, isOptionEqualToValue, filterSelectedOptions]);

    const renderInput = useCallback(
        params => (
            <AsyncAutocompleteInput
                {...params}
                name={name}
                originalName={originalName}
                loading={loading}
                label={label}
                variant={variant}
                contrast={contrast}
                autofocus={autofocus}
                autofill={autofill}
                onError={handleError}
                error={!!error}
                helperText={error && error.split && t(`errors.${error.split('.').join('')}`)}
                InputProps={{
                    ...params.InputProps,
                    ...InputProps,
                    inputRef: inputRef || textInputRef,
                    startAdornment:
                        params?.InputProps?.startAdornment || InputProps?.startAdornment,
                    endAdornment: extraButton ? (
                        <>
                            {params.InputProps.endAdornment}
                            <Box className={_classes.extraButton}>{extraButton}</Box>
                        </>
                    ) : (
                        params.InputProps.endAdornment
                    ),
                    className: classNames({
                        [params.InputProps.className]: true,
                        [InputProps && InputProps.className]: true,
                    }),
                }}
                InputLabelProps={{ ...params.InputLabelProps, ...InputLabelProps }}
                can={can}
                {...other}
            />
        ),
        [
            loading,
            label,
            variant,
            contrast,
            InputProps,
            extraButton,
            InputLabelProps,
            other,
            _classes,
            name,
            autofill,
            error,
            handleError,
            t,
            can,
        ]
    );

    const PaperComponent = useCallback(
        ({ className: _className, children }) => (
            <Paper className={_className}>
                {children}
                {footer && <Box className={_classes.footer}>{footer}</Box>}
            </Paper>
        ),
        [footer, _classes]
    );

    useEffect(() => {
        if (initialValue) {
            setUncontrolledInputValue(initialValue);
        }
    }, [initialValue, setUncontrolledInputValue]);

    return (
        <Autocomplete
            className={classNames({
                [_classes.fixedWidth]: !fullWidth,
                [className]: true,
            })}
            data-test-id={`${name}Autocomplete`}
            classes={innerClasses}
            value={value}
            isOptionEqualToValue={isOptionEqualToValue}
            getOptionLabel={getOptionLabel}
            options={extendedOptions}
            getOptionDisabled={getOptionDisabled}
            filterOptions={filterOptions}
            filterSelectedOptions={filterSelectedOptions}
            onChange={onChange}
            inputValue={inputValue}
            onInputChange={handleChange}
            loading={loading}
            multiple={multiple}
            renderTags={renderTags}
            handleHomeEndKeys={false}
            renderInput={renderInput}
            ref={innerRef}
            renderOption={renderOption}
            freeSolo={freeSolo}
            clearOnBlur={clearOnBlur}
            blurOnSelect={blurOnSelect}
            disableCloseOnSelect={disableCloseOnSelect}
            disablePortal={disablePortal}
            noOptionsText={t('components.AsyncAutocomplete.noOptionsText')}
            fullWidth={fullWidth}
            disabled={disabled || !canDo}
            ListboxComponent={virtualized ? VirtualizedListFixedHeight : undefined}
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            openText={t('components.AsyncAutocomplete.openText')}
            closeText={t('components.AsyncAutocomplete.closeText')}
            clearText={clearText}
            autoHighlight={autoHighlight}
        />
    );
};

AsyncAutocomplete.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.shape({}),
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape({})])
        ),
    ]),
    initialValue: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.shape({}),
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape({})])
        ),
    ]),
    name: PropTypes.string,
    originalName: PropTypes.string,
    threshold: PropTypes.number,
    fetch: PropTypes.func.isRequired,
    renderOption: PropTypes.func.isRequired,
    renderTags: PropTypes.func,
    filterOptions: PropTypes.func,
    filterSelectedOptions: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    isOptionEqualToValue: PropTypes.func,
    getOptionLabel: PropTypes.func,
    label: PropTypes.string,
    variant: PropTypes.string,
    contrast: PropTypes.bool,
    multiple: PropTypes.bool,
    InputProps: PropTypes.shape({
        className: PropTypes.string,
    }),
    InputLabelProps: PropTypes.shape({}),
    disabled: PropTypes.bool,
    freeSolo: PropTypes.bool,
    open: PropTypes.bool,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    clearOnBlur: PropTypes.bool,
    blurOnSelect: PropTypes.bool,
    disableCloseOnSelect: PropTypes.bool,
    fullWidth: PropTypes.bool,
    getOptionDisabled: PropTypes.func,
    className: PropTypes.string,
    classes: PropTypes.shape({}),
    inputValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onInputChange: PropTypes.func,
    extraButton: PropTypes.node,
    footer: PropTypes.node,
    innerRef: RefPropType,
    inputRef: RefPropType,
    popupSize: PropTypes.string,
    virtualized: PropTypes.bool,
    autofocus: PropTypes.bool,
    autofill: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    can: PropTypes.string,
    disablePortal: PropTypes.bool,
    clearText: PropTypes.string,
    autoHighlight: PropTypes.bool,
};

AsyncAutocomplete.defaultProps = {
    value: null,
    initialValue: null,
    name: null,
    originalName: null,
    threshold: 0,
    filterOptions: options => options,
    filterSelectedOptions: true,
    isOptionEqualToValue: undefined,
    getOptionLabel: undefined,
    renderTags: undefined,
    label: undefined,
    variant: 'outlined',
    contrast: false,
    InputProps: null,
    InputLabelProps: null,
    disabled: false,
    freeSolo: false,
    open: undefined,
    onOpen: null,
    onClose: null,
    clearOnBlur: false,
    blurOnSelect: false,
    disableCloseOnSelect: false,
    getOptionDisabled: undefined,
    className: null,
    classes: null,
    multiple: false,
    fullWidth: false,
    inputValue: undefined,
    onInputChange: undefined,
    extraButton: null,
    innerRef: undefined,
    inputRef: undefined,
    popupSize: 'medium',
    footer: null,
    virtualized: false,
    autofocus: false,
    autofill: 'false',
    can: null,
    disablePortal: false,
    clearText: null,
    autoHighlight: false,
};

export default AsyncAutocomplete;
