import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Lock as LockIcon } from '@mui/icons-material';

import { TaskPropType } from '../../modules/tasks/propTypes';
import TaskType from '../taskTypes/TaskType';
import TaskDuration from '../taskDurations/TaskDuration';
import Date, { DATE_TYPES } from '../utils/Date';
import Avatars from '../icons/Avatars';
import Markdown from '../utils/Markdown';
import Priority from '../priorities/Priority';
import CategoryDisplay from '../categories/CategoryDisplay';
import GridDisplay from '../layout/GridDisplay';
import TaskStatus from '../taskStatus/TaskStatus';
import DueDate from '../utils/DueDate';

const useStyles = makeStyles(theme => ({
    aside: {
        gridRowEnd: 'span 3',
        textAlign: 'right',
    },

    alignRow: {
        display: 'flex',
    },

    spacing: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
}));

const TaskInfos = ({ task }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            <GridDisplay columns="min-content auto auto" spacing={2}>
                <Typography color="textSecondary">{t('components.TaskInfos.category')}:</Typography>
                <Box>
                    <CategoryDisplay categoryId={task && task.category_id} />
                </Box>
                <Box className={classes.aside}>
                    <Avatars userIds={task.responsibles} mainIds={task.responsibles_main} flipped />
                </Box>
                <Typography color="textSecondary">{t('components.TaskInfos.type')}:</Typography>
                <Typography data-test-id="taskType">
                    <TaskType typeId={task.task_type_id} text={task.task_type_text} />
                </Typography>
                <Typography color="textSecondary">{t('components.TaskInfos.duration')}:</Typography>
                <Typography data-test-id="taskDuration">
                    {task.duration_value && (
                        <TaskDuration
                            durationId={task.task_duration_id}
                            value={task.duration_value}
                        />
                    )}
                </Typography>
                <Typography color="textSecondary">{t('components.TaskInfos.due')}:</Typography>
                <Typography data-test-id="taskDue" className={classes.alignRow}>
                    <DueDate
                        dueAt={task.due_at}
                        hasTime={task.has_due_time}
                        locked={task.due_locked}
                        dateType={DATE_TYPES.END}
                    />
                </Typography>
                <br />
                <Typography color="textSecondary">{t('components.TaskInfos.priority')}:</Typography>
                <Priority priorityId={task.priority_id} />
                <br />
                <Typography color="textSecondary">{t('components.TaskInfos.status')}:</Typography>
                <TaskStatus taskStatusId={task.task_status_id} />
                <br />
                <Typography color="textSecondary">
                    {t('components.TaskInfos.description')}:
                </Typography>
                <Markdown content={task.description} style={{ gridColumnEnd: 'span 2' }} />
            </GridDisplay>
        </>
    );
};

TaskInfos.propTypes = {
    task: TaskPropType.isRequired,
};

export default TaskInfos;
