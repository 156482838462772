import { EMPTY_LIST } from '../redux/resource/selectors';

export const selectListsByIds = state => state.lists.byId;

export const selectListById = (state, listId) => selectListsByIds(state)[listId];

export const selectListProp = (state, listId, key) => {
    const list = selectListById(state, listId);
    return list ? list[key] : null;
};

export const selectListResource = (state, listId) => selectListProp(state, listId, 'resource');
export const selectListStaticParams = (state, listId) =>
    selectListProp(state, listId, 'staticParams');
export const selectListDataIds = (state, listId) =>
    selectListProp(state, listId, 'allIds') || EMPTY_LIST;
export const selectListPage = (state, listId) => selectListProp(state, listId, 'page') || 1;
export const selectListPageCount = (state, listId) => selectListProp(state, listId, 'count');
export const selectListItemsTotal = (state, listId) => selectListProp(state, listId, 'total');
export const selectListSearch = (state, listId) => selectListProp(state, listId, 'search');
export const selectListOrderBy = (state, listId) =>
    selectListProp(state, listId, 'orderBy') || EMPTY_LIST;
export const selectListParams = (state, listId) => selectListProp(state, listId, 'params');
export const selectListIsLoading = (state, listId) => !!selectListProp(state, listId, 'loading');
export const selectListHighlights = (state, listId) => selectListProp(state, listId, 'highlights');
export const selectListIsInitialized = (state, listId) =>
    !!selectListProp(state, listId, 'initialized');

export const selectListDataById = (state, listId, dataId) => {
    const resource = selectListResource(state, listId);

    if (!resource) {
        throw new Error(`List "${listId}" must be initialized first!`);
    }

    /**
     * This assumes all resource slices are located in the store by their FOO_RESOURCE identifier
     */
    return state[resource].byId[dataId];
};

const emptyArray = [];

export const selectListData = (state, listId, ignoreInit = false) => {
    const resource = selectListResource(state, listId);

    if (!resource) {
        if (ignoreInit) {
            return emptyArray;
        } else {
            throw new Error(`List "${listId}" must be initialized first!`);
        }
    }

    const itemIds = selectListDataIds(state, listId);

    const byId = selectListProp(state, listId, 'byId');

    /**
     * This assumes all resource slices are located in the store by their FOO_RESOURCE identifier
     */
    return itemIds.reduce(
        (carry, itemId) => ({ ...carry, [itemId]: (byId ? byId : state[resource].byId)[itemId] }),
        {}
    );
};

export const selectFirstLastListData = (state, listId, first = true, ignoreInit = false) => {
    const resource = selectListResource(state, listId);

    if (!resource) {
        if (ignoreInit) {
            return emptyArray;
        } else {
            throw new Error(`List "${listId}" must be initialized first!`);
        }
    }

    const itemId = selectListDataIds(state, listId)[first ? 0 : -1];

    /**
     * This assumes all resource slices are located in the store by their FOO_RESOURCE identifier
     */
    return state[resource].byId[itemId];
};

// --- GROUPED LISTS ---

export const selectResourceDataById = (state, listId) => {
    const resource = selectListResource(state, listId);

    return state[resource]?.byId || {};
};

export const selectListIsAggregatesLoading = (state, listId) =>
    selectListProp(state, listId, 'aggsLoading');
export const selectListTotalAggregate = (state, listId) => selectListProp(state, listId, 'aggs');

export const selectListGroupingByFields = (state, listId, fields) => {
    const groupKey = typeof fields === 'string' ? fields : fields.join();
    const list = selectListById(state, listId);
    return list?.grouped?.[groupKey] || {};
};

export const selectListGroupByFieldsLoading = (state, listId, fields) =>
    selectListGroupingByFields(state, listId, fields).loading || false;
export const selectListGroupByFieldsGroups = (state, listId, fields) =>
    selectListGroupingByFields(state, listId, fields).groups || [];
export const selectListGroupByFieldsAggs = (state, listId, fields) =>
    selectListGroupingByFields(state, listId, fields).aggs || {};
export const selectListGroupByFieldsNestedLists = (state, listId, fields) =>
    selectListGroupingByFields(state, listId, fields).nestedLists || {};
export const selectListGroupByFieldsNestedList = (state, listId, fields, nestedListKey) =>
    selectListGroupByFieldsNestedLists(state, listId, fields)?.[nestedListKey] || {};
