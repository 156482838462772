import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TextInput from '../form/formik/TextInput';
import { makeStyles } from '@mui/styles';
import DateInput from '../form/formik/DateInput';
import NumberInput from '../form/formik/NumberInput';
import { WORK_DURATION_DAYS } from '../../modules/contracts/schema';
import DurationInput from '../form/formik/DurationInput';
import MoneyInput from '../form/formik/MoneyInput';
import MultilineSubmit from '../form/formik/MultilineSubmit';
import Duration from '../utils/Duration';
import { useFormikContext } from 'formik';
import { verifiedDurationInt } from '../../modules/datetime/utils';

const useStyles = makeStyles(theme => ({
    inputRow: {
        width: '100%',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

    h4: {
        color: theme.palette.primary.main,
        paddingBottom: theme.spacing(2),
        fontSize: '.875rem',
    },
}));

export const TotalWorkDuration = ({ variant }) => {
    const { values } = useFormikContext();

    if (!values) return 'null';

    const total = WORK_DURATION_DAYS.map(day => `work_duration_${day}`).reduce(
        (acc, curr) => acc + verifiedDurationInt(values[curr]),
        0
    );

    return typeof total === 'number' ? (
        <Duration durationValue={total} asDecimal variant={variant || 'h5'} fontSize=".875rem" />
    ) : null;
};

const ContractFormBody = ({ autoFocus }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Grid container spacing={4}>
            <Grid item md={8}>
                <Typography variant="h4" className={classes.h4}>
                    {t('components.EmployeeContract.Data')}
                </Typography>

                <TextInput
                    name="personnel_number"
                    label={t('components.EmployeeContract.personnel_number')}
                    className={classes.inputRow}
                    size="small"
                    autoFocus={autoFocus}
                />

                <Grid container spacing={2}>
                    <Grid item xs>
                        <DateInput
                            name="started_at"
                            label={t('components.EmployeeContract.started_at')}
                            className={classes.inputRow}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs>
                        <DateInput
                            name="ended_at"
                            label={t('components.EmployeeContract.ended_at')}
                            className={classes.inputRow}
                            size="small"
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <NumberInput
                            name="vacation_days"
                            label={t('components.EmployeeContract.vacation_days')}
                            className={classes.inputRow}
                            size="small"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <NumberInput
                            name="cancellation_period"
                            label={t('components.EmployeeContract.cancellation_period')}
                            className={classes.inputRow}
                            size="small"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <MoneyInput
                            name="costs"
                            label={t('components.EmployeeContract.costs')}
                            className={classes.inputRow}
                            size="small"
                            fullWidth
                        />
                    </Grid>
                </Grid>

                <MultilineSubmit>
                    <TextInput
                        name="description"
                        label={t('components.EmployeeContract.description')}
                        className={classes.inputRow}
                        size="small"
                        minRows={3}
                        multiline
                        fullWidth
                    />
                </MultilineSubmit>
            </Grid>

            <Grid item md={4}>
                <Typography variant="h4" className={classes.h4}>
                    {t('components.EmployeeContract.schedule')}
                </Typography>
                {WORK_DURATION_DAYS.map((day, index) => (
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={6}>
                            {t(`components.EmployeeContract.${day}`)}:
                        </Grid>
                        <Grid item xs={6}>
                            <DurationInput
                                key={index}
                                name={`work_duration_${day}`}
                                type="time"
                                variant="standard"
                                size="small"
                                noLabel
                                errorAsTooltip
                                short
                            />
                        </Grid>
                    </Grid>
                ))}
                <Grid container spacing={1} alignItems="center" mt={1}>
                    <Grid item xs={6}>
                        <Typography variant="h5" fontSize=".875rem">
                            {t('components.EmployeeContract.sum')}:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TotalWorkDuration />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

ContractFormBody.propTypes = {
    autoFocus: PropTypes.bool,
};

ContractFormBody.defaultProps = {
    autoFocus: false,
};

export default ContractFormBody;
