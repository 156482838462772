import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Box, LinearProgress } from '@mui/material';

import {
    cardPath,
    contactPath,
    contactsPath,
    employeePath,
    employeesPath,
    homePath,
    kitchenSinkPath,
    loginPath,
    logoutPath,
    playgroundPath,
    resourcePath,
    resourcesPath,
    schedulesPath,
    tasksPath,
    userCalendarPath,
    planningCalendarPath,
    protocolItemFormPath,
    outlookPluginPath,
    serviceLoginPath,
    settingsPath,
} from './paths';
import PrivateRoute from './PrivateRoute';

//const Error404Page = lazy(() => import('../pages/Error404Page'));
const LoginPage = lazy(() => import('../pages/LoginPage'));
const ServiceLoginPage = lazy(() => import('../pages/ServiceLoginPage'));
const LogoutPage = lazy(() => import('../pages/LogoutPage'));
const Home = lazy(() => import('../pages/Home'));
const ContactsPage = lazy(() => import('../pages/ContactsPage'));
const ResourcesPage = lazy(() => import('../pages/ResourcesPage'));
const Playground = lazy(() => import('../pages/Playground'));
const KitchenSink = lazy(() => import('../pages/KitchenSink'));
const OfficeManagementPage = lazy(() => import('../pages/OfficeManagementPage'));
const SchedulesPage = lazy(() => import('../pages/SchedulesPage'));
const TasksPage = lazy(() => import('../pages/TasksPage'));
const UserCalendarPage = lazy(() => import('../pages/UserCalendarPage'));
const PlanningCalendarPage = lazy(() => import('../pages/PlanningCalendarPage'));
const SettingsPage = lazy(() => import('../pages/SettingsPage'));
const ProtocolItemFormPage = lazy(() => import('../pages/ProtocolItemFormPage'));
const OutlookTaskpane = lazy(() => import('../kumoOutlookPlugin/src/OutlookTaskpane'));

const loader = (
    <Box display="flex" justifyContent="center" alignItems="center">
        <LinearProgress size={50} />
    </Box>
);

const Routes = ({ children }) => (
    <Router>
        <Suspense fallback={loader}>
            <Switch>
                <Route path={loginPath} component={LoginPage} />
                <Route path={serviceLoginPath} component={ServiceLoginPage} />
                <Route path={logoutPath} exact component={LogoutPage} />
                <Route path={playgroundPath} component={Playground} />
                <Route path={kitchenSinkPath} component={KitchenSink} />
                <Route path={outlookPluginPath} component={OutlookTaskpane} />
                <PrivateRoute
                    path={[contactPath(':contactId'), contactsPath]}
                    component={ContactsPage}
                />

                <PrivateRoute
                    path={[
                        employeePath(':employeeId', ':initTab'),
                        employeePath(':employeeId'),
                        employeesPath,
                        '/management',
                    ]}
                    component={OfficeManagementPage}
                />
                <PrivateRoute
                    path={[
                        resourcePath(':resourceId', { initTab: ':initTab' }),
                        resourcePath(':resourceId', { cloneResourceId: ':cloneResourceId' }),
                        resourcePath(':resourceId'),
                        resourcesPath,
                    ]}
                    component={ResourcesPage}
                />
                <PrivateRoute path={schedulesPath} component={SchedulesPage} />
                <PrivateRoute path={[tasksPath]} component={TasksPage} />
                <PrivateRoute path={userCalendarPath} component={UserCalendarPage} />
                <PrivateRoute path={planningCalendarPath} component={PlanningCalendarPage} />
                <PrivateRoute path={settingsPath} component={SettingsPage} />
                <PrivateRoute path={protocolItemFormPath} component={ProtocolItemFormPage} />

                {/* --- INSERT NEW ROUTES HERE --- */}

                <PrivateRoute
                    path={[contactPath(':contactId', ':cardId'), cardPath(':cardId'), homePath]}
                    component={Home}
                />

                {/* <PrivateRoute exact path={usersPath} role={ADMIN_ROLE} component={UsersPage} /> */}
                {/* <Route component={Error404Page} /> */}
            </Switch>
        </Suspense>
        {children}
    </Router>
);

export default Routes;
