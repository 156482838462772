import { groupBy } from 'lodash/collection';

const NO_GROUP = 'no-group';

export const combineFilters = filters => {
    /**
     * Rules to combine multiple filters:
     * - filters with different groups (or without any group) are combined with `$and`
     * - filters with the same group are combined with `$or` (inside `$and`)
     */

    const byGroup = filters.reduce((carry, filter) => {
        const group = filter.group || NO_GROUP;
        if (!carry[group]) {
            // eslint-disable-next-line no-param-reassign
            carry[group] = [];
        }
        carry[group].push(filter);
        return carry;
    }, {});

    const mainFilters = Object.entries(byGroup).reduce(
        (carry, [group, groupFilters]) => [
            ...carry,
            ...(group === NO_GROUP || groupFilters.length < 2
                ? groupFilters
                : [
                      {
                          $or: groupFilters,
                      },
                  ]),
        ],
        []
    );

    return mainFilters.length > 1
        ? {
              $and: mainFilters,
          }
        : mainFilters;
};

export const groupFilters = filters => {
    return groupBy(filters, 'filter_group');
};

export const combineFiltersByGroup = groupedFilters => {
    const filterGroups = Object.keys(groupedFilters);
    const filters = {};

    filterGroups.forEach(filterGroup => {
        filters[filterGroup === 'undefined' ? 'filters' : filterGroup] = combineFilters(
            groupedFilters[filterGroup]
        );
    });

    return filters;
};

export const flattenFilters = filters => {
    if (Array.isArray(filters)) {
        /* already flattened */
        return filters;
    }

    const flattened = [];
    if (!filters || !filters.$and) {
        return flattened;
    }

    filters.$and.forEach(groupFilter => {
        if (groupFilter.$or) {
            flattened.push(...groupFilter.$or);
        } else {
            flattened.push(groupFilter);
        }
    });

    return flattened;
};
