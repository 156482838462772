import React, { useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { OPTION_COMPONENT_CONFIGS } from '../../../../modules/form/autocomplete';
import CloseIconButton from '../../../buttons/CloseIconButton';

const SELECT_REASON = 'selectOption';

const TypedAutocompleteSelectionComponent = ({
    value: currentSelected,
    onChange,
    onAbort,
    children,
}) => {
    const selectedEvent = useRef(null);
    const selectionRemainingOptions = useRef([]);
    const [selectedComponentOption, setSelectedComponentOption] = useState(null);

    const handleReset = () => {
        selectedEvent.current = null;
        selectionRemainingOptions.current = [];

        setSelectedComponentOption(null);
    };

    const handleChange = (event, newValue, reason) => {
        const addedOption = newValue?.[newValue?.length - 1];

        if (reason === SELECT_REASON && addedOption?.component in OPTION_COMPONENT_CONFIGS) {
            selectedEvent.current = event;
            selectionRemainingOptions.current = currentSelected;

            setSelectedComponentOption(addedOption);
        } else {
            if (selectedComponentOption) {
                handleReset();
            }

            onChange(event, newValue, reason);
        }
    };

    const handleValue = value => {
        onChange(
            selectedEvent.current,
            [
                ...selectionRemainingOptions.current,
                {
                    ...selectedComponentOption,
                    value,
                },
            ],
            SELECT_REASON
        );

        handleReset();
    };

    const handleAbort = () => {
        handleReset();

        if (typeof onAbort === 'function') {
            onAbort();
        }
    };

    const renderOptionComponent = option => {
        if (
            option?.component &&
            typeof option.component === 'string' &&
            option.component in OPTION_COMPONENT_CONFIGS
        ) {
            const { component, label } = option;
            const { Component } = OPTION_COMPONENT_CONFIGS[component];

            return (
                <>
                    <Box
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 32,
                            right: 0,
                            bottom: 0,
                            background: '#fff',
                        }}
                    >
                        <Component label={label} onChange={handleValue} />
                    </Box>
                    <Box
                        style={{
                            position: 'absolute',
                            width: 42,
                            top: 0,
                            right: 0,
                            bottom: 0,
                        }}
                    >
                        <CloseIconButton onClick={handleAbort} />
                    </Box>
                </>
            );
        }

        return null;
    };

    return (
        <Box style={{ position: 'relative' }}>
            {children({ onChange: handleChange })}
            {renderOptionComponent(selectedComponentOption)}
        </Box>
    );
};

TypedAutocompleteSelectionComponent.propTypes = {
    value: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onChange: PropTypes.func.isRequired,
    onAbort: PropTypes.func,
};

export default TypedAutocompleteSelectionComponent;
