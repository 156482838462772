import { createResourceSlice } from '../redux/resource/createResourceSlice';
import { BILLING_TYPE_RESOURCE } from '../api/resources';

const billingTypeSlice = createResourceSlice({
    resource: BILLING_TYPE_RESOURCE,
    byKey: '',
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
    indexBillingTypes,
    showBillingType,
    storeBillingType,
    updateBillingType,
    archiveBillingType,
    restoreBillingType,
    destroyBillingType,
} = billingTypeSlice.actions;

export const {
    selectBillingTypesById,
    selectBillingTypeById,
    makeBillingTypesByIdsSelector,
    selectAllBillingTypeIds,
    selectAllBillingTypes,
    selectBillingTypesLoading,
    selectBillingTypesInitialized,
    selectBillingTypeLoading,
    selectBillingTypeInitialized,
    selectBillingTypeIdsBy,
    makeBillingTypesBySelector,
    selectBillingTypeLoadingBy,
    selectBillingTypeInitializedBy,
} = billingTypeSlice.selectors;

export const { useBillingTypes, useBillingType, useBillingTypesBy } = billingTypeSlice.hooks;

export default billingTypeSlice.reducer;
