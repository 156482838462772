import { createResourceSlice } from '../redux/resource/createResourceSlice';
import { EXPENSE_TYPE_RESOURCE } from '../api/resources';

const expenseTypeSlice = createResourceSlice({
    resource: EXPENSE_TYPE_RESOURCE,
    byKey: '',
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
    indexExpenseTypes,
    showExpenseType,
    storeExpenseType,
    updateExpenseType,
    archiveExpenseType,
    restoreExpenseType,
    destroyExpenseType,
} = expenseTypeSlice.actions;

export const {
    selectExpenseTypesById,
    selectExpenseTypeById,
    makeExpenseTypesByIdsSelector,
    selectAllExpenseTypeIds,
    selectAllExpenseTypes,
    selectExpenseTypesLoading,
    selectExpenseTypesInitialized,
    selectExpenseTypeLoading,
    selectExpenseTypeInitialized,
    selectExpenseTypeIdsBy,
    makeExpenseTypesBySelector,
    selectExpenseTypeLoadingBy,
    selectExpenseTypeInitializedBy,
} = expenseTypeSlice.selectors;

export const { useExpenseTypes, useExpenseType, useExpenseTypesBy } = expenseTypeSlice.hooks;

export default expenseTypeSlice.reducer;
