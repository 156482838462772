// Value should match material-react-table aggregation function names (https://www.material-react-table.com/docs/guides/aggregation#built-in-aggregation-functions)
export const AGGREGATION_TYPE = {
    SUM: 'sum',
    SUM_IF: 'sum_if',
    SUM_NOTNULL: 'sum_notnull',
    COUNT: 'count',
    COUNT_NULL: 'count_null',
    MAX: 'max',
};

export const makeFormatAggregationFunc = (aggregationFieldKey, aggregatedFields, formatFunc) => {
    if (aggregatedFields && aggregationFieldKey && aggregationFieldKey in aggregatedFields) {
        return () => {
            const value = aggregatedFields[aggregationFieldKey];

            if (typeof formatFunc === 'function') {
                return formatFunc(value, aggregatedFields);
            }

            return value;
        };
    }
    return null;
};

export const getAggregationFieldKey = ({ aggregationConfig, valueKey, apiKey, accessorKey, id }) =>
    aggregationConfig?.alias || valueKey || apiKey || accessorKey || id;
