import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import SearchFilterAutocomplete from '../form/formik/autocomplete/SearchFilterAutocomplete';
import Form from '../form/formik/Form';
import Checkbox from '../form/formik/Checkbox';
import { filterList, suggestList } from '../../modules/lists/actions';
import { READ } from '../../modules/abilities/actions';
import { selectListIsLoading } from '../../modules/lists/selectors';
import { applyGroupedFilters } from '../form/base/autocomplete/BaseSearchFilterAutocomplete';

const useStyles = makeStyles(theme => ({
    archived: {
        flex: '0 1 0',
        padding: theme.spacing(1),
    },
    fullWidth: {
        maxWidth: '100%',
        width: '100%',
    },
}));

/**
 * Search applying directly to useResourceList and all components using it (i.e. ResourceTable)
 *
 * @param listId
 *          string should be matching resourceList config (i.e. 'journals')
 *          and formatted lowercase with own resource/model first
 *          and then it's filter criteria for optimal search pill seeding/config
 *          (i.e. 'journals.employees.2' will match 'journals', 'journals.employees' and the full version)
 * @param globalParams
 * @param fetchParams
 * @param fetchAction
 * @param suggestParams
 * @param showArchived
 * @param contrast
 * @param contexts
 * @param fullWidth
 * @param muteDirty
 * @param other
 * @return {JSX.Element}
 * @constructor
 */

const ResourceSearch = ({
    listId,
    globalParams,
    fetchParams,
    fetchAction,
    suggestParams,
    showArchived,
    contrast,
    contexts,
    fullWidth,
    muteDirty,
    ...other
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const loading = useSelector(state => selectListIsLoading(state, listId));

    const initialValues = { groupedFilters: {}, withTrashed: false };

    const handleSubmit = useCallback(
        values => {
            if (!loading && typeof values === 'object') {
                const fetchFunc = fetchAction || filterList;
                const preppedValues = applyGroupedFilters(values);

                dispatch(
                    fetchFunc(listId, {
                        ...globalParams,
                        ...fetchParams,
                        ...preppedValues,
                        contexts,
                    })
                );
            }
        },
        [loading, globalParams, fetchParams, dispatch, contexts, listId, fetchAction]
    );

    const fetch = useCallback(
        search => suggestList(listId, { ...globalParams, ...suggestParams, ...search }),
        [globalParams, suggestParams, listId]
    );

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            autoSubmit
            muteDirty={muteDirty}
        >
            <Grid
                container
                alignItems="center"
                className={classNames({ [classes.fullWidth]: fullWidth })}
            >
                <Grid item xs>
                    <SearchFilterAutocomplete
                        type={listId}
                        contrast={contrast}
                        fetchAction={fetch}
                        submitOnChange
                        fullWidth
                        I={READ}
                        {...other}
                    />
                </Grid>
                {showArchived && (
                    <Grid item xs className={classes.archived}>
                        <Checkbox
                            name="withTrashed"
                            label={t('components.ResourceSearch.showArchived')}
                            size="small"
                            contrast={contrast}
                            color="secondary"
                        />
                    </Grid>
                )}
            </Grid>
        </Form>
    );
};

ResourceSearch.propTypes = {
    listId: PropTypes.string.isRequired,
    globalParams: PropTypes.shape({}),
    fetchParams: PropTypes.shape({}),
    fetchAction: PropTypes.func,
    suggestParams: PropTypes.shape({}),
    showArchived: PropTypes.bool,
    contexts: PropTypes.arrayOf(PropTypes.string),
    contrast: PropTypes.bool,
    fullWidth: PropTypes.bool,
    muteDirty: PropTypes.bool,
};

ResourceSearch.defaultProps = {
    globalParams: {},
    fetchParams: {},
    fetchAction: null,
    suggestParams: {},
    showArchived: false,
    contexts: [],
    contrast: false,
    fullWidth: false,
    muteDirty: false,
};

export default ResourceSearch;
