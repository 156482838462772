import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import {
    selectAllExpenseTypes,
    useExpenseTypes,
} from '../../modules/expenseTypes/expenseTypeSlice';
import Select from '../form/formik/Select';
import { useSelector } from 'react-redux';

const ExpenseTypeSelect = ({ name, ...other }) => {
    const { loading } = useExpenseTypes();
    const expenseTypes = useSelector(selectAllExpenseTypes);

    const options = useMemo(() => {
        if (Array.isArray(expenseTypes) && expenseTypes.length) {
            return expenseTypes.map(({ id, name }) => ({ label: name, value: id }));
        }

        return [];
    }, [expenseTypes]);

    return <Select name={name} options={options} {...other} />;
};

ExpenseTypeSelect.propTypes = {
    name: PropTypes.string.isRequired,
};

export default ExpenseTypeSelect;
