import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import SlimDialog, { SLIM_DIALOG_WIDTH } from '../../dialogs/slimDialog/SlimDialog';
import ExpensesForm from '../ExpensesForm';
import { IdPropType } from '../../../modules/proptypes';
import { Grid } from '@mui/material';
import Button from '../../form/base/Button';
import Badge from '@mui/material/Badge';
import SubmitButton from '../../form/formik/SubmitButton';
import { CREATE, UPDATE } from '../../../modules/abilities/actions';
import { useTranslation } from 'react-i18next';
import { storeExpense } from '../../../modules/expenses/expenseSlice';
import { useDispatch } from 'react-redux';

const ExpensesDialog = ({ cardId, create, initialValues: initValues, open, onClose, onExited }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleSubmit = values => {
        return dispatch(storeExpense(values)).then(() => {
            onClose();
        });
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <SlimDialog
            title={t('components.Expenses.create')}
            open={open}
            width={SLIM_DIALOG_WIDTH.MEDIUM}
            onClose={handleClose}
            //actions={toolbarActions}
        >
            <ExpensesForm cardId={cardId} onSubmit={handleSubmit}>
                <Grid item display="flex" alignItems="end">
                    <Button onClick={handleClose} variant="outlined" size="medium">
                        {t('abort')}
                    </Button>
                </Grid>
                <Grid item display="flex" alignItems="end">
                    <SubmitButton I={create ? CREATE : UPDATE} size="medium" />
                </Grid>
            </ExpensesForm>
        </SlimDialog>
    );
};

ExpensesDialog.propTypes = {
    cardId: IdPropType,
    create: PropTypes.bool,
    initialValues: PropTypes.shape({}),
    open: PropTypes.bool,
};

ExpensesDialog.defaultProps = {
    cardId: null,
    create: false,
    initialValues: null,
    open: false,
};
export default ExpensesDialog;
