import React, { memo, useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { List } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useResourceList } from '../../modules/lists/hooks';
import LoadingBox from '../loading/LoadingBox';
import BaseSearchFilterAutocomplete from '../form/base/autocomplete/BaseSearchFilterAutocomplete';
import ResourceWaypoint from '../table/resource/ResourceWaypoint';
import ResourceListItem from './ResourceListItem';
import Empty from '../layout/Empty';

const ResourceList = ({
    listId,
    resource,
    search,
    primary,
    secondary,
    emptyLabel,
    onClick,
    fetchParams,
    initialOrderBy,
    index,
    ListComponent,
    ItemComponent,
    itemProps,
    criteria,
}) => {
    const { t } = useTranslation();

    const { dataIds, dataSelector, loading, initialized, handleNextPage, handleSearch } =
        useResourceList({
            listId,
            resource,
            fetchParams,
            criteria,
            initialOrderBy,
            index,
            autoload: true,
            continuous: true,
        });

    const handleSearchByFilters = useCallback(
        groupedFilters => handleSearch({ ...groupedFilters }),
        [handleSearch]
    );

    return (
        <LoadingBox loading={loading && !initialized}>
            {search && (
                <BaseSearchFilterAutocomplete
                    type={listId}
                    name="groupedFilters"
                    onChange={handleSearchByFilters}
                    fullWidth
                    fetchAction={search}
                />
            )}

            <ListComponent>
                {dataIds &&
                    dataIds.map(itemId => (
                        <ItemComponent
                            key={itemId}
                            itemId={itemId}
                            selector={dataSelector}
                            primary={primary}
                            secondary={secondary}
                            onClick={onClick}
                            {...itemProps}
                        />
                    ))}
                {(!loading || initialized) && dataIds && dataIds.length === 0 && (
                    <Empty label={emptyLabel || t('components.List.empty')} />
                )}
                <ResourceWaypoint listId={listId} onEnter={handleNextPage} />
            </ListComponent>
        </LoadingBox>
    );
};

ResourceList.propTypes = {
    listId: PropTypes.string,
    resource: PropTypes.string.isRequired,
    search: PropTypes.func,
    primary: PropTypes.string,
    secondary: PropTypes.string,
    emptyLabel: PropTypes.string,
    onClick: PropTypes.func,
    fetchParams: PropTypes.shape({}),
    initialOrderBy: PropTypes.arrayOf(PropTypes.string),
    index: PropTypes.bool,
    criteria: PropTypes.shape({}),
    ListComponent: PropTypes.func,
    ItemComponent: PropTypes.func,
    itemProps: PropTypes.shape({}),
};

ResourceList.defaultProps = {
    listId: null,
    search: null,
    primary: null,
    secondary: null,
    emptyLabel: null,
    onClick: null,
    fetchParams: {},
    initialOrderBy: [],
    index: false,
    criteria: null,
    ListComponent: ({ children }) => <List>{children}</List>,
    ItemComponent: ResourceListItem,
    itemProps: null,
};

export default memo(ResourceList);
