import * as Yup from 'yup';

export const YupId = () => Yup.number().integer();

export const YupTextEditor = () =>
    Yup.mixed().transform(
        (current, original) => (original && original.getCurrentContent().getPlainText()) || null
    );

export const YupFile = () =>
    Yup.object().shape({
        filename: Yup.string().required('required'),
        path: Yup.string().trim().required('required').max(255, 'string255MaxLength'),

        /**
         * Validating the file absolutely tanks the performance with big files.
         */
        // file: Yup.mixed()
        //     .nullable()
        //     .when('uploaded', (uploaded, schema) =>
        //         uploaded ? schema : schema.required('required')
        //     ),

        uploaded: Yup.boolean().nullable(),
        destroy: Yup.boolean().nullable(),
        metadata: Yup.array().nullable(),
        mime: Yup.string().nullable(),
        hidePath: Yup.boolean().nullable(),
        processed: Yup.boolean().nullable(),
    });

export const YupSimpleSearch = () =>
    Yup.object().shape({
        responsible: Yup.boolean(),
        mentioned: Yup.boolean(),
        uuid: Yup.string(),
        cardTitle: Yup.string(),
    });

export const YupJson = () =>
    Yup.string().test('is-json', function (value) {
        const { path, createError } = this; // Destructuring the validation context to get path and createError

        if (value === undefined || value === null) {
            return true; // Skip validation if value is not provided
        }

        try {
            JSON.parse(value);
            return true;
        } catch (error) {
            return createError({ path, message: { noTranslateMessage: error.message } }); //createError({ path, message: error.message });
        }
    });

export const YupDate = () =>
    Yup.date()
        .nullable()
        .transform(date => (date instanceof Date && !isNaN(date) ? date : null));
