import React, { useCallback, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useFieldFast } from '../../../../modules/form/hooks';
import BaseSearchFilterAutocomplete from '../../base/autocomplete/BaseSearchFilterAutocomplete';
import { flattenFilters } from '../../../../modules/searches/utils';

const SearchFilterAutocomplete = ({
    name: overrideName,
    label,
    fullWidth,
    contrast,
    fetchAction,
    type,
    noSave,
    shortcuts,
    I,
    onChange,
    ...other
}) => {
    const { name, originalName } = useMemo(
        () => ({
            name: overrideName || 'groupedFilters',
            originalName: overrideName || 'filters',
        }),
        [overrideName]
    );
    const [, { initialValue }, { setValue }] = useFieldFast(name);

    const initialFilters = useMemo(() => flattenFilters(initialValue), [initialValue]);

    const handleValue = useCallback(
        (groupedFilters, allFlatFilters, flatCombinedFilters) => {
            if (onChange) {
                onChange(allFlatFilters, flatCombinedFilters, groupedFilters);
            }

            setValue(groupedFilters);
        },
        [onChange, setValue]
    );

    return (
        <BaseSearchFilterAutocomplete
            onChange={handleValue}
            name={name}
            originalName={originalName}
            initialFilters={initialFilters}
            label={label}
            fullWidth={fullWidth}
            contrast={contrast}
            fetchAction={fetchAction}
            type={type}
            noSave={noSave}
            shortcuts={shortcuts}
            I={I}
            {...other}
        />
    );
};

SearchFilterAutocomplete.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    fullWidth: PropTypes.bool,
    contrast: PropTypes.bool,
    fetchAction: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    noSave: PropTypes.bool,
    shortcuts: PropTypes.shape({}),
    I: PropTypes.string,
    onChange: PropTypes.func,
};

SearchFilterAutocomplete.defaultProps = {
    name: null,
    label: null,
    fullWidth: false,
    contrast: false,
    noSave: false,
    shortcuts: null,
    I: null,
    onChange: null,
};

export default SearchFilterAutocomplete;
