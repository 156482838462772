import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { makeStyles } from '@mui/styles';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import enLocale from 'date-fns/locale/en-US';
import deLocale from 'date-fns/locale/de';

import { getFormFieldError } from '../../../modules/loading/utils';
import { ErrorPropType } from '../../../modules/loading/proptypes';
import ContextualCan from '../../../modules/abilities/ContextualCan';
import { READ, WRITE } from '../../../modules/abilities/actions';
import { DateTimePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { useContextualCan } from '../../../modules/abilities/hooks';
import { importDate, normalizeDateTime, PATTERN } from '../../../modules/datetime/utils';

const useStyles = makeStyles({
    input: {
        margin: 0,
        width: 202,
    },

    fullWidth: {
        width: '100%',
    },
});

const localeMap = {
    en: enLocale,
    de: deLocale,
};

const DateTimeInput = ({
    name,
    originalName,
    label,
    onChange,
    errors,
    fullWidth,
    disabled,
    I,
    noFixedWidth,
    isRangeEnd,
    slotProps,
    ...other
}) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [field, meta, helpers] = useField(name);
    const error = getFormFieldError(name, errors, meta);
    const [locale, setLocale] = useState('de');
    const canDo = useContextualCan(I ? I : WRITE, originalName || name);

    useEffect(() => {
        setLocale(i18n.language.slice(0, 2));
    }, [i18n]);

    return (
        <ContextualCan I={READ} field={originalName || name}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[locale] || 'en'}>
                <DateTimePicker
                    {...field}
                    value={typeof field.value === 'string' ? importDate(field.value) : field.value}
                    label={label}
                    slotProps={{
                        textField: {
                            size: 'small',
                            variant: 'outlined',
                            error: !!error,
                            helperText: error && t(`errors.${error.split('.').join('')}`),
                            onBlur: field.onBlur,
                        },
                        ...slotProps,
                    }}
                    onChange={date => {
                        helpers.setValue(
                            normalizeDateTime(date, { normalizeOnlyBelowMinutes: true, isRangeEnd })
                        );
                        helpers.setTouched(true);
                        if (onChange) {
                            onChange(date);
                        }
                    }}
                    onError={err => {
                        if (err !== meta.error && err) {
                            helpers.setError(err);
                        }
                    }}
                    format={PATTERN.localDateTime}
                    ampm={false}
                    className={
                        noFixedWidth ? '' : `${classes.input} ${fullWidth ? classes.fullWidth : ''}`
                    }
                    disabled={disabled || !canDo}
                    {...other}
                />
            </LocalizationProvider>
        </ContextualCan>
    );
};

DateTimeInput.propTypes = {
    name: PropTypes.string.isRequired,
    originalName: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    errors: ErrorPropType,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    noFixedWidth: PropTypes.bool,
    I: PropTypes.string,
    isRangeEnd: PropTypes.bool,
    slotProps: PropTypes.shape({}),
};

DateTimeInput.defaultProps = {
    originalName: null,
    label: null,
    onChange: null,
    errors: null,
    fullWidth: false,
    disabled: false,
    noFixedWidth: false,
    I: null,
    isRangeEnd: false,
    slotProps: {},
};

export default DateTimeInput;
