import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { IdPropType } from '../../../modules/proptypes';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE, UPDATE } from '../../../modules/abilities/actions';
import { Box, Grid } from '@mui/material';
import Button from '../../form/base/Button';
import SubmitButton from '../../form/formik/SubmitButton';
import { useTranslation } from 'react-i18next';
import SlimDialog, { SLIM_DIALOG_WIDTH } from '../../dialogs/slimDialog/SlimDialog';
import Badge from '@mui/material/Badge';
import { contractSchema, getContractInitialValues } from '../../../modules/contracts/schema';
import { assembleBulkValues, cleanPartialUpdateValues } from '../../../modules/form/bulk';
import { CONTRACT_RESOURCE } from '../../../modules/api/resources';
import { selectContractsById } from '../../../modules/contracts/selectors';
import { storeContract, updateContract } from '../../../modules/contracts/actions';
import ContractForm from '../ContractForm';
import { formatContractSubmit } from '../../../modules/contracts/utils';
import { useSnackbar } from '../../../modules/snackbar/hooks';

const ContractDialog = ({
    contractIds,
    employeeId,
    create,
    initialValues: initValues,
    open,
    onClose,
    onExited,
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const title = useMemo(
        () => t(`components.ContractDialog.${create ? 'create' : 'bulk'}`),
        [create]
    );

    const contractsById = useSelector(selectContractsById);

    const { bulkMode, initialValues, contracts } = useMemo(() => {
        const bulkMode = !create && Array.isArray(contractIds) && contractIds.length >= 1;

        if (bulkMode) {
            const { contracts } = contractIds.reduce(
                (carry, contractId) => {
                    const item = contractsById[parseInt(contractId)];
                    return {
                        contracts: [...carry.contracts, item],
                    };
                },
                { contracts: [] }
            );
            const bulkValues = assembleBulkValues(contracts);

            return {
                bulkMode,
                initialValues: {
                    ...bulkValues,
                    ended_at: null,
                },
                contracts,
            };
        }

        return {
            bulkMode,
            initialValues: {
                ...(initValues || getContractInitialValues()),
                ended_at: null,
            },
            contracts: [],
        };
    }, [create, contractIds, initValues, contractsById]);

    const dispatchSubmit = values => {
        const formattedValues = formatContractSubmit(values);

        if (create) {
            delete formattedValues.id;
            return dispatch(
                storeContract({
                    ...formattedValues,
                    employee_id: employeeId,
                })
            );
        }

        if (bulkMode) {
            const items = {
                ...formattedValues,
                id: contractIds,
            };

            const cleaned = cleanPartialUpdateValues(initialValues, items);

            return dispatch(updateContract(cleaned));
        }

        if (Array.isArray(contractIds) && contractIds.length === 1) {
            return dispatch(
                updateContract({
                    ...formattedValues,
                    employee_id: employeeId,
                })
            );
        }

        console.error('could not distinguish submit mode (create/update/bulkUpdate)');
        return null;
    };

    const handleSubmit = values => {
        const submit = dispatchSubmit(values);

        if (submit) {
            submit.then(() => {
                onClose();
                enqueueSnackbar(t('components.ContractDialog.contractSaved'), {
                    variant: 'success',
                });
            });
        }

        return submit;
    };

    const handleDelete = () => null;

    const handleClose = props => {
        onClose(props);
    };

    const toolbarActions = useMemo(() => null, []);

    return (
        <SlimDialog
            title={title}
            open={open}
            width={SLIM_DIALOG_WIDTH.MEDIUM}
            onDelete={create || contractIds.length ? null : handleDelete}
            onClose={handleClose}
            actions={toolbarActions}
        >
            <Box m={2}>
                <ContractForm
                    initialValues={initialValues}
                    employeeId={employeeId}
                    subject={contracts.length === 1 ? contracts[0] : CONTRACT_RESOURCE}
                    validationSchema={bulkMode ? null : contractSchema}
                    onSubmit={handleSubmit}
                    actionsComponent={
                        <>
                            <Grid item display="flex" alignItems="end">
                                <Button onClick={handleClose} variant="outlined" size="medium">
                                    {t('abort')}
                                </Button>
                            </Grid>
                            <Grid item display="flex" alignItems="end">
                                <Badge
                                    invisible={contractIds.length < 2}
                                    badgeContent={contractIds.length}
                                    color="primary"
                                >
                                    <SubmitButton I={create ? CREATE : UPDATE} size="medium" />
                                </Badge>
                            </Grid>
                        </>
                    }
                />
            </Box>
        </SlimDialog>
    );
};

ContractDialog.propTypes = {
    contractIds: PropTypes.arrayOf(IdPropType.isRequired).isRequired,
    employeeId: IdPropType,
    create: PropTypes.bool,
    initialValues: PropTypes.shape({}),
    open: PropTypes.bool,
};

ContractDialog.defaultProps = {
    employeeId: null,
    create: false,
    initialValues: null,
    open: false,
};

export default ContractDialog;
