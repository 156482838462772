/**
 *
 * @param columns
        // Just set to `groupConfig: true` if no detailed config is needed (column.id and column.accessorKey match requirements)
        groupConfig: {
            --- Implicit generation of config details using key or even column.id if suiting ---
            key: 'task',

            --- Define config details without implicit use ---

            // Fallback: <KEY>_id
            field: 'task_id',

            // Fallbacks: <KEY>.name or column.accessorKey if suiting
            label: 'task.name',
            order: 'task.name',

            --- Extra values ---
            includedFields: { alias: 'path...' },
        },

         --- Detail Config --- // Might be in columnType config aswell
         enableGroupedCell: true|false,
         enableGroupedCellWhenUnknown: true|false,
 * @return {*}
 */

export const getRemoteGroupConfig = columns => {
    if (Array.isArray(columns)) {
        return columns.reduce(
            (carry, column) => {
                if (column?.groupConfig) {
                    const { id, accessorKey, groupConfig } = column;
                    const { includedFields, ...groupConfigRest } = groupConfig;
                    const groupBaseKey = groupConfig?.key || id;
                    const namedFieldKeyFallback = accessorKey.includes(`${groupBaseKey}.`)
                        ? accessorKey
                        : `${groupBaseKey}.name`;

                    return {
                        groupConfigs: {
                            ...carry.groupConfigs,
                            [groupBaseKey]: {
                                ...(groupConfigRest || {}),
                                ...(includedFields || {}),
                                key: groupBaseKey,
                                field: groupConfig?.field || `${groupBaseKey}_id`,
                                label: groupConfig?.label || namedFieldKeyFallback,
                                order: groupConfig?.order || namedFieldKeyFallback,
                            },
                        },
                        idKeyMapping:
                            column?.id === groupBaseKey
                                ? carry.idKeyMapping
                                : {
                                      ...carry.idKeyMapping,
                                      [column?.id]: groupBaseKey,
                                  },
                    };
                }

                return carry;
            },
            { groupConfigs: {}, idKeyMapping: {} }
        );
    }

    return { groupConfigs: {}, idKeyMapping: {} };
};

export const getActiveGroupConfigs = (groupConfig, activeFields) =>
    activeFields.map(field => groupConfig[field]);

export const getGroupEntries = ({ row, nestedLists = null, fullListById = null }) => {
    if (row.key) {
        if (row.groups) {
            return row.groups;
        }

        const groupNestedListKey = row.key;
        const nestingParams = row?.params || {};

        if (nestedLists && groupNestedListKey in nestedLists && fullListById) {
            const { allIds, page, count } = nestedLists[groupNestedListKey];

            if (Array.isArray(allIds)) {
                const entries = allIds.map(entryId => fullListById[entryId] || entryId);
                const params = {
                    ...nestingParams,
                    page: page + 1,
                };

                return [
                    ...entries,
                    ...(page < count ? [{ groupNestedListKey, loading: true, params }] : []),
                ];
            }
        }

        if (row?.total > 0) {
            return [
                {
                    groupNestedListKey,
                    loading: true,
                    params: {
                        ...nestingParams,
                        page: 1,
                    },
                },
            ];
        }

        return []; //ToDo: Empty-State?
    }
    return null;
};

export const getGroupByFromGroupKey = groupKey =>
    typeof groupKey === 'string' ? groupKey.split(',') : null;
