export const ABSENCE_TYPE_RESOURCE = 'AbsenceType';
export const ACTIVITY_RESOURCE = 'Activity';
export const ADDRESS_RESOURCE = 'Address';
export const ADDRESS_TYPE_RESOURCE = 'AddressType';
export const ATTACHMENT_RESOURCE = 'Attachment';
export const AUDIT_RESOURCE = 'Audit';
export const CARD_RESOURCE = 'Card';
export const CARD_STATUS_RESOURCE = 'CardStatus';
export const CATEGORY_RESOURCE = 'Category';
export const CHECKLIST_ITEM_RESOURCE = 'ChecklistItem';
export const CHECKLIST_RESOURCE = 'Checklist';
export const COMPANY_RESOURCE = 'Company';
export const CONTACTABLE_RESOURCE = 'Contactable';
export const CONTACT_DATA_RESOURCE = 'ContactData';
export const CONTACT_RESOURCE = 'Contact';
export const CONTACT_TYPE_RESOURCE = 'ContactType';
export const CONTRACT_RESOURCE = 'Contract';
export const DATACONTAINER_RESOURCE = 'Datacontainer';
export const DATACONTAINER_TYPE_RESOURCE = 'DatacontainerType';
export const EMPLOYEE_RESOURCE = 'Employee';
export const IDENTIFIER_RESOURCE = 'Identifier';
export const INVOICE_RESOURCE = 'Invoice';
export const JOURNAL_RESOURCE = 'Journal';
export const METADATA_RESSOURCE = 'Metadata';
export const PERSON_FUNCTION_RESOURCE = 'PersonFunction';
export const PERSON_RESOURCE = 'Person';
export const PERSONAL_ACCESS_TOKEN_RESOURCE = 'PersonalAccessToken';
export const PRIORITY_RESOURCE = 'Priority';
export const PROTOCOL_RESOURCE = 'Protocol';
export const PROTOCOL_ITEM_RESOURCE = 'ProtocolItem';
export const PROTOCOL_ITEM_TYPE_RESOURCE = 'ProtocolItemType';
export const PROTOCOL_TEMPLATE_RESOURCE = 'ProtocolTemplate';
export const RESOURCE_CLASS_RESOURCE = 'ResourceClass';
export const RESOURCE_RESOURCE = 'Resource';
export const RESOURCE_TYPE_RESOURCE = 'ResourceType';
export const ROLE_RESOURCE = 'Role';
export const APPOINTMENT_RESOURCE = 'Appointment';
export const APPOINTMENT_TYPE_RESOURCE = 'AppointmentType';
export const GROUP_RESOURCE = 'Group';
export const SEARCH_RESOURCE = 'Search';
export const SETTING_RESSOURCE = 'Setting';
export const TAG_RESOURCE = 'Tag';
export const TASK_RESOURCE = 'Task';
export const TASK_DURATION_RESOURCE = 'TaskDuration';
export const TASK_STATUS_RESOURCE = 'TaskStatus';
export const TASK_TYPE_RESOURCE = 'TaskType';
export const USER_RESOURCE = 'User';
export const WORKTIME_SUMMARY_RESOURCE = 'WorktimeSummary';
export const EXPENSE_RESOURCE = 'Expense';
export const EXPENSE_TYPE_RESOURCE = 'ExpenseType';
export const BILLING_TYPE_RESOURCE = 'BillingType';

export const resources = [
    {
        name: ABSENCE_TYPE_RESOURCE,
        endpoint: '/absence_types',
    },
    {
        name: ACTIVITY_RESOURCE,
        endpoint: '/activities',
        extra: [
            {
                action: 'read',
                endpoint: '/activities/mark_all_as_read',
                method: 'put',
            },
            {
                action: 'destroyAll',
                endpoint: '/activities/all',
                method: 'delete',
            },
        ],
    },
    {
        name: ADDRESS_RESOURCE,
        endpoint: '/addresses',
    },
    {
        name: ADDRESS_TYPE_RESOURCE,
        endpoint: '/address_types',
    },
    {
        name: ATTACHMENT_RESOURCE,
        endpoint: '/attachments',
    },
    {
        name: AUDIT_RESOURCE,
        endpoint: '/audits',
    },
    {
        name: CARD_RESOURCE,
        endpoint: '/cards',
        extra: [
            {
                action: 'bounds',
                endpoint: '/cards/bounds',
                method: 'get',
            },
        ],
        socket: {
            disableDefaultHandling: ['store', 'update'],
        },
    },
    {
        name: CARD_STATUS_RESOURCE,
        endpoint: '/card_statuses',
    },
    {
        name: CATEGORY_RESOURCE,
        endpoint: '/categories',
    },
    {
        name: CHECKLIST_ITEM_RESOURCE,
        endpoint: '/checklist_items',
    },
    {
        name: CHECKLIST_RESOURCE,
        endpoint: '/checklists',
    },
    {
        name: COMPANY_RESOURCE,
        endpoint: '/companies',
    },
    {
        name: CONTACTABLE_RESOURCE,
        endpoint: '/contactables',
    },
    {
        name: CONTACT_DATA_RESOURCE,
        endpoint: '/contact_datas',
    },
    {
        name: CONTACT_RESOURCE,
        endpoint: '/contacts',
    },
    {
        name: CONTACT_TYPE_RESOURCE,
        endpoint: '/contact_types',
    },
    {
        name: CONTRACT_RESOURCE,
        endpoint: '/contracts',
    },
    {
        name: DATACONTAINER_RESOURCE,
        endpoint: '/datacontainers',
    },
    {
        name: DATACONTAINER_TYPE_RESOURCE,
        endpoint: '/datacontainer_types',
    },
    {
        name: EMPLOYEE_RESOURCE,
        endpoint: '/employees',
        extra: [
            {
                action: 'getSummary',
                endpoint: '/employees/summary',
                method: 'get',
            },
        ],
    },
    {
        name: IDENTIFIER_RESOURCE,
        endpoint: '/identifier',
    },
    {
        name: JOURNAL_RESOURCE,
        endpoint: '/journals',
    },
    {
        name: INVOICE_RESOURCE,
        endpoint: '/invoices',
    },
    {
        name: PERSON_FUNCTION_RESOURCE,
        endpoint: '/person_functions',
    },
    {
        name: PERSON_RESOURCE,
        endpoint: '/persons',
    },
    {
        name: PERSONAL_ACCESS_TOKEN_RESOURCE,
        endpoint: '/personal_access_tokens',
    },
    {
        name: PRIORITY_RESOURCE,
        endpoint: '/priorities',
    },
    {
        name: PROTOCOL_RESOURCE,
        endpoint: '/protocols',
    },
    {
        name: PROTOCOL_ITEM_RESOURCE,
        endpoint: '/protocol_items',
    },
    {
        name: PROTOCOL_ITEM_TYPE_RESOURCE,
        endpoint: '/protocol_item_types',
    },
    {
        name: PROTOCOL_TEMPLATE_RESOURCE,
        endpoint: '/protocol_templates',
    },
    {
        name: RESOURCE_CLASS_RESOURCE,
        endpoint: '/resource_classes',
    },
    {
        name: RESOURCE_RESOURCE,
        endpoint: '/resources',
    },
    {
        name: RESOURCE_TYPE_RESOURCE,
        endpoint: '/resource_types',
    },
    {
        name: ROLE_RESOURCE,
        endpoint: '/roles',
    },
    {
        name: APPOINTMENT_RESOURCE,
        endpoint: '/appointments',
        extra: [
            {
                action: 'fetchAvailability',
                endpoint: '/appointments/availability',
                method: 'get',
            },
        ],
    },
    {
        name: APPOINTMENT_TYPE_RESOURCE,
        endpoint: '/appointment_types',
    },
    {
        name: GROUP_RESOURCE,
        endpoint: '/groups',
    },
    {
        name: SEARCH_RESOURCE,
        endpoint: '/searches',
    },
    {
        name: SETTING_RESSOURCE,
        endpoint: '/settings',
    },
    {
        name: TAG_RESOURCE,
        endpoint: '/tags',
    },
    {
        name: TASK_RESOURCE,
        endpoint: '/tasks',
    },
    {
        name: TASK_DURATION_RESOURCE,
        endpoint: '/task_durations',
    },
    {
        name: TASK_STATUS_RESOURCE,
        endpoint: '/task_statuses',
    },
    {
        name: TASK_TYPE_RESOURCE,
        endpoint: '/task_types',
    },
    {
        name: USER_RESOURCE,
        endpoint: '/users',
        extra: [
            {
                action: 'resetPassword',
                endpoint: '/users/:id/reset_password',
                method: 'put',
            },
        ],
    },
    {
        name: WORKTIME_SUMMARY_RESOURCE,
        endpoint: '/worktime_summary',
    },
    {
        name: EXPENSE_RESOURCE,
        endpoint: '/expenses',
    },
    {
        name: EXPENSE_TYPE_RESOURCE,
        endpoint: '/expense_types',
    },
    {
        name: BILLING_TYPE_RESOURCE,
        endpoint: 'billing_types',
    },
];

export const methods = {
    index: 'get',
    show: 'get',
    store: 'post',
    update: 'put',
    destroy: 'delete',
    restore: 'patch',

    search: 'post',
    autocomplete: 'get',
    suggest: 'get',

    aggregate: 'get',
    group: 'get',

    multiupdate: 'put', // ToDo: Update without id?
};
