import * as Yup from 'yup';
import { YupDate, YupId } from '../form/schema';

export const expensesSchema = Yup.object().shape({
    date: YupDate().required('required'),
    quantity: Yup.number().required('required'),
    amount: Yup.number(),
    card_id: YupId().required('required'),
    billed_by: YupId(),
    expense_type_id: YupId(),
    billing_type_id: YupId(),
    id_invoice: Yup.string(),
});
