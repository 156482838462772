import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import { Box, Typography } from '@mui/material';
import { useFieldFast } from '../../../../modules/form/hooks';
import TypedAutocomplete from '../../base/autocomplete/TypedAutocomplete';
import { suggestIdentifiers } from '../../../../modules/identifiers/identifierSlice';
import { selectSettingsByKey } from '../../../../modules/settings/selectors';
import Confirmation from '../../Confirmation';
import KeyboardWatcher from '../../base/KeyboardWatcher';
import { useSnackbar } from '../../../../modules/snackbar/hooks';
import { IDENTIFIER_TYPE_SETTINGS } from '../../../../modules/datacontainers/utils';

const IdentifierAutocomplete = ({
    name,
    label,
    contrast,
    variant,
    fullWidth,
    size,
    type,
    fetchParams,
    onChange,
    confirmRemove,
    ...other
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [{ value }, { error }, helpers] = useFieldFast(name);
    const [confirmValue, setConfirmValue] = useState(null);
    const [tempValue, setTempValue] = useState(null);
    const inputValue = useRef(null);
    const recentSuggest = useRef(null);
    const ref = useRef(null);
    const popupConfirm = useRef(null);
    const popupRemove = useRef(null);
    const {
        allow_duplicates: allowDuplicates,
        confirm_duplicates: confirmDuplicates,
        allow_custom: allowWritten = true,
    } = useSelector(state => selectSettingsByKey(state, type && IDENTIFIER_TYPE_SETTINGS[type]));

    const typeLabel = useMemo(() => t(`components.IdentifierAutocomplete.${type}`), [type]);
    const handleFetch = useCallback(
        query => {
            inputValue.current = query || null;
            return async () => {
                return dispatch(suggestIdentifiers({ ...fetchParams, ...query })).then(result => {
                    recentSuggest.current = result.data.suggestions;

                    return {
                        data: {
                            suggestions: result.data.suggestions.filter(
                                suggestion => suggestion.value !== value
                            ),
                        },
                    };
                });
            };
        },
        [value, dispatch]
    );

    const checkDuplicates = newValue =>
        new Promise((resolve, reject) => {
            if (Array.isArray(recentSuggest.current) && recentSuggest.current.length && newValue) {
                const matchIndex = recentSuggest.current.findIndex(
                    suggestion => suggestion.value === newValue
                );
                return resolve(matchIndex === -1);
            }

            return resolve(false);
        });

    const handleChange = newValue => {
        if (newValue) {
            helpers.setValue(newValue.value);
        } else if (confirmValue) {
            helpers.setValue(confirmValue);
            setConfirmValue(null);
        } else if (typeof inputValue.current?.q === 'string') {
            helpers.setValue(inputValue.current?.q);
        } else {
            helpers.setValue(null);
            setTempValue(null);
        }

        onChange();
    };

    const handleAbort = () => {
        helpers.setValue(null);
        setConfirmValue(null);
    };

    const handleChangeIntent = (newValue, oldValue, setValue) => {
        if (newValue) {
            checkDuplicates(newValue.value).then(hasDuplicate => {
                if (hasDuplicate) {
                    if (allowDuplicates) {
                        setConfirmValue(newValue.value);
                        popupConfirm.current.open(ref.current);
                    }
                } else {
                    handleChange(newValue);
                }
            });
        } else if (confirmRemove) {
            popupRemove.current.open(ref.current);
            setValue(oldValue);
        }
    };

    const handleWritten = (event, handleBlur) => {
        handleBlur();

        if (allowWritten && typeof inputValue.current?.q === 'string') {
            const { q: idCustom, duplicates } = inputValue.current;

            if (duplicates) {
                if (allowDuplicates && !confirmDuplicates) {
                    handleChange({ value: idCustom });
                } else if (allowDuplicates) {
                    popupConfirm.current.open(ref.current);
                } else {
                    enqueueSnackbar(t('components.IdentifierAutocomplete.noDuplicate'), {
                        variant: 'error',
                    });
                }
            } else {
                handleChange({ value: idCustom });
            }
        }
    };

    useEffect(() => {
        if (value && value.value) setTempValue({ value });
        else if (value) setTempValue(value);
        else setTempValue('');
    }, [value]);

    return (
        <Box width={fullWidth ? '100%' : null} maxHeight={38}>
            <KeyboardWatcher onEnter={handleWritten} width={fullWidth ? '100%' : null}>
                <TypedAutocomplete
                    name={name}
                    fetchAction={handleFetch}
                    initialValue={tempValue}
                    value={tempValue}
                    onChange={handleChangeIntent}
                    getOptionLabel={option => (option && option.value) || option || ''}
                    getOptionDisabled={option => option.disabled}
                    innerRef={ref}
                    extraParams={{ type }}
                    label={label || t(`components.IdentifierAutocomplete.${type || 'notype'}`)}
                    fullWidth={fullWidth}
                    contrast={contrast}
                    variant={variant}
                    size={size}
                    autofocus={false}
                    noOptionsText={t('components.IdentifierAutocomplete.nothing')}
                    clearText={t('components.IdentifierAutocomplete.clear')}
                    blurOnSelect
                    forceEmptyInitialValue
                    {...other}
                />
                <Typography color="error">{error}</Typography>
            </KeyboardWatcher>
            <Confirmation
                popupId="ConfirmIdentifierDuplicate"
                popupStateRef={popupConfirm}
                onConfirm={handleChange}
                onAbort={handleAbort}
                label={t('components.IdentifierAutocomplete.duplicate')}
                icon={<ControlPointDuplicateIcon />}
            />
            <Confirmation
                popupId="ConfirmIdentifierRemove"
                popupStateRef={popupRemove}
                onConfirm={() => handleChange(null)}
                onAbort={() => {}}
                label={t(`components.IdentifierAutocomplete.remove`).replace('%TYPE%', typeLabel)}
                color="danger"
            />
        </Box>
    );
};

IdentifierAutocomplete.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    contrast: PropTypes.bool,
    variant: PropTypes.string,
    fullWidth: PropTypes.bool,
    size: PropTypes.string,
    type: PropTypes.string.isRequired,
    fetchParams: PropTypes.shape({}),
    confirmRemove: PropTypes.bool,
    onChange: PropTypes.func,
};

IdentifierAutocomplete.defaultProps = {
    contrast: false,
    variant: 'outlined',
    fullWidth: false,
    size: 'medium',
    fetchParams: {},
    confirmRemove: false,
    onChange: () => null,
};

export default IdentifierAutocomplete;
