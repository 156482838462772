import React from 'react';
import * as PropTypes from 'prop-types';
import Form from '../form/formik/Form';
import DateInput from '../form/formik/DateInput';
import Checkbox from '../form/formik/Checkbox';
import { exportDate } from '../../modules/datetime/utils';
import { Grid } from '@mui/material';
import ExpenseTypeSelect from '../expenseType/ExpenseTypeSelect';
import BillingTypeSelect from '../billingType/BillingTypeSelect';
import NumberInput from '../form/formik/NumberInput';
import MoneyInput from '../form/formik/MoneyInput';
import ValueChangeAutofocus from '../form/formik/ValueChangeAutofocus';
import IdentifierAutocomplete from '../form/formik/autocomplete/IdentifierAutocomplete';
import { IDENTIFIER_TYPES } from '../../modules/datacontainers/utils';
import { IdPropType } from '../../modules/proptypes';
import TextInput from '../form/formik/TextInput';
import { EXPENSE_RESOURCE } from '../../modules/api/resources';
import { useTranslation } from 'react-i18next';
import { expensesSchema } from '../../modules/expenses/schema';
import ExpenseTypeFormWatcher from './ExpenseTypeFormWatcher';

const ExpensesForm = ({ cardId, onSubmit, children }) => {
    const { t } = useTranslation();

    return (
        <Form
            initialValues={{
                card_id: cardId,
                date: new Date(),
                quantity: 1,
            }}
            subject={EXPENSE_RESOURCE}
            validationSchema={expensesSchema}
            onSubmit={onSubmit}
        >
            <Grid container spacing={2} p={2}>
                <Grid item xs={6}>
                    <DateInput name="date" label={t('components.Expenses.date')} fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <ValueChangeAutofocus
                        name="billed_at"
                        FocusComponent={({ focusRef }) => (
                            <IdentifierAutocomplete
                                label={t('components.Expenses.invoice')}
                                name="id_invoice"
                                fetchParams={cardId ? { card_id: cardId } : null}
                                type={IDENTIFIER_TYPES.INVOICE}
                                inputRef={focusRef}
                                size="small"
                                confirmRemove
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <ExpenseTypeFormWatcher>
                    {(expenseType, billingType) => (
                        <>
                            {billingType?.name === 'nicht abrechnen' ? null : ( //ToDo: KEYWORD in BE, not .name! Below 'Grid'->'xs' aswell
                                <Grid item xs={1}>
                                    <Checkbox
                                        name="billed_at"
                                        label={t('components.Expenses.billed')}
                                        formatValue={(checked, fieldValue) =>
                                            checked ? fieldValue || exportDate(null, true) : null
                                        }
                                        size="small"
                                        labelTooltip
                                    />
                                </Grid>
                            )}
                            <Grid item xs={billingType?.name === 'nicht abrechnen' ? 6 : 5}>
                                <NumberInput
                                    name="quantity"
                                    label={t('components.Expenses.quantity')}
                                    fullWidth
                                />
                            </Grid>
                        </>
                    )}
                </ExpenseTypeFormWatcher>
                <Grid item xs={6}>
                    <MoneyInput name="amount" label={t('components.Expenses.amount')} fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <ExpenseTypeSelect
                        name="expense_type_id"
                        label={t('components.Expenses.expenseType')}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <BillingTypeSelect
                        name="billing_type_id"
                        label={t('components.Expenses.billingType')}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        name="description"
                        label={t('components.Expenses.description')}
                        size="small"
                        minRows={4}
                        multiline
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={2} justifyContent="flex-end">
                        {children}
                    </Grid>
                </Grid>
            </Grid>
        </Form>
    );
};

ExpensesForm.propTypes = {
    cardId: IdPropType,
    onSubmit: PropTypes.func.isRequired,
};

ExpensesForm.defaultProps = {
    cardId: null,
};

export default ExpensesForm;
