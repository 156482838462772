/**
 * Usage: <Button onClick={() => openEmployeeDialog({ contactId: 1337 })}>open contact dialog</Button>
 */

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import TabDialog from '../dialogs/tabDialog/TabDialog';
import EmployeeInternalData from './pages/EmployeeInternalData';
import EmployeeContract from './pages/EmployeeContract';
import GroupsData from '../groups/GroupsData';
import EmployeeDialogActions from './EmployeeDialogActions';
import { selectContactById } from '../../modules/contacts/selectors';
import { selectEmployeeById } from '../../modules/employees/selectors';
import { indexGroups } from '../../modules/groups/actions';
import PersonContactPage from '../contacts/dialog/pages/PersonContactPage';
import { showContact } from '../../modules/contacts/actions';
import TabDialogActions from '../dialogs/tabDialog/TabDialogActions';
import EditButton from '../buttons/EditButton';
import { READ, UPDATE } from '../../modules/abilities/actions';
import { useDialog } from '../dialogs/DialogContext';
import { showEmployee, storeEmployee } from '../../modules/employees/actions';
import { archiveEmployee } from '../../modules/employees/employeesSlice';
import EmployeeCalendar from './pages/EmployeeCalendar';
import { useCan } from '../../modules/abilities/hooks';
import { PERSONAL_ACCESS_TOKEN_RESOURCE, USER_RESOURCE } from '../../modules/api/resources';
import EmployeePersonalAccessToken from './pages/EmployeePersonalAccessToken';
import EmployeeKumoConnect from './pages/EmployeeKumoConnect';
import EmployeeJournal from './pages/EmployeeJournal';
import { selectUser } from '../../modules/auth/selectors';

const EmployeeDialog = ({ employeeId, open, initTab, onDone, onClose, onExited }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const submitRef = useRef(null);
    const [loading, setLoading] = useState(!!employeeId);
    const currentEmployee = useSelector(state => selectEmployeeById(state, employeeId));

    const [edit, setEdit] = useState(false);

    const contactId = useMemo(
        () => (currentEmployee ? currentEmployee.contact_id : null),
        [currentEmployee]
    );

    const { openEmployeeDialog } = useDialog();

    const currentContact = useSelector(state => selectContactById(state, contactId));
    const userId = useMemo(() => {
        if (currentEmployee && currentEmployee.user && currentEmployee.user.id) {
            return currentEmployee.user.id;
        }

        if (currentEmployee && currentEmployee.user_id) {
            return currentEmployee.user_id;
        }

        return null;
    }, [currentEmployee]);

    const canEditEmployee = useCan(UPDATE, currentEmployee);
    const canEditContact = useCan(UPDATE, currentContact);
    const canReadPersonalAccessToken = useCan(READ, {
        created_by: userId,
        __type: PERSONAL_ACCESS_TOKEN_RESOURCE,
    });
    const canEditUserKumoConnect = useCan(
        UPDATE,
        {
            id: userId,
            __type: USER_RESOURCE,
        },
        'kumo_connect'
    );

    /** quick-solution for employee journals permissions */
    const user = useSelector(selectUser);
    const canDoEmployeeJournal = useMemo(() => {
        if (user.employee_id === employeeId) return true;
        const allowedRoleNames = ['root', 'admin', 'hr'];
        const userRoleNames = Object.values(user.permissions?.roles || {}).map(role => role.name);
        return userRoleNames.some(userRoleName => allowedRoleNames.includes(userRoleName));
    }, [user]);
    /* END quick-solution */

    useEffect(() => {
        if (contactId) {
            setLoading(true);
            dispatch(showContact({ id: contactId })).finally(() => {
                setLoading(false);
            });

            return;
        }

        if (!employeeId) setEdit(true);
        else setEdit(false);
    }, [contactId, dispatch]);

    /* load groups */
    useEffect(() => {
        dispatch(indexGroups({ limit: 500 }));
    }, [dispatch]);

    const handleDone = useCallback(
        async (params = {}) => {
            const response = !employeeId
                ? await dispatch(storeEmployee({ contact_id: params.contactId }))
                : await dispatch(showEmployee({ id: employeeId }));
            if (!employeeId) {
                openEmployeeDialog({ employeeId: response.data.id });
            }

            if (onDone) {
                onDone(response);
            }

            setEdit(false);
        },
        [setEdit, openEmployeeDialog, employeeId, dispatch, onDone]
    );

    const tabs = useMemo(() => {
        return [
            {
                name: 'data',
                title: t('components.EmployeeDialog.data'),
                disabled: false,
                content: (
                    <PersonContactPage
                        contactId={contactId}
                        parentDialog={openEmployeeDialog}
                        contextParentId={null}
                        contextCardId={null}
                        submitRef={submitRef}
                        extraRef={null}
                        edit={edit}
                        openContact={false}
                        hasIdCustom={false}
                        onDone={handleDone}
                    />
                ),
            },
            {
                name: 'internalData',
                title: t('components.EmployeeDialog.internalData'),
                disabled: !contactId,
                content: (
                    <EmployeeInternalData
                        employeeId={employeeId}
                        userId={userId}
                        submitRef={submitRef}
                        onDone={handleDone}
                    />
                ),
            },
            {
                name: 'contract',
                title: t('components.EmployeeDialog.contract'),
                disabled: !contactId,
                borderless: true,
                content: (
                    <EmployeeContract
                        employeeId={employeeId}
                        contractId={currentEmployee && currentEmployee.contract_id}
                        submitRef={submitRef}
                    />
                ),
            },
            {
                name: 'groups',
                title: t('components.EmployeeDialog.groups'),
                disabled: !contactId,
                content: <GroupsData employeeId={employeeId} />,
            },
            {
                name: 'calendar',
                title: t('components.EmployeeDialog.calendar'),
                disabled: !employeeId,
                borderless: true,
                content: <EmployeeCalendar employeeId={employeeId} />,
            },
            ...(canDoEmployeeJournal
                ? [
                      {
                          name: 'journal',
                          title: t('components.EmployeeDialog.journal'),
                          disabled: !employeeId,
                          borderless: true,
                          content: <EmployeeJournal employeeId={employeeId} />,
                      },
                  ]
                : []),
            ...(canReadPersonalAccessToken
                ? [
                      {
                          name: 'personalAccessToken',
                          title: t('components.EmployeeDialog.personalAccessToken'),
                          content: <EmployeePersonalAccessToken userId={userId} />,
                      },
                  ]
                : []),
            ...(canEditUserKumoConnect
                ? [
                      {
                          name: 'kumoConnect',
                          title: t('components.EmployeeDialog.kumoConnect'),
                          content: <EmployeeKumoConnect userId={userId} />,
                      },
                  ]
                : []),
        ];
    }, [
        contactId,
        userId,
        submitRef,
        currentEmployee,
        employeeId,
        t,
        edit,
        handleDone,
        openEmployeeDialog,
    ]);

    const tabActions = {
        data: (
            <TabDialogActions
                ref={submitRef}
                left={
                    edit ? null : (
                        <EditButton
                            onClick={() => setEdit(true)}
                            disabled={
                                !!(currentContact && currentContact.sync_date) ||
                                !(canEditEmployee && canEditContact)
                            }
                        />
                    )
                }
            />
        ),
        internalData: (
            <EmployeeDialogActions>
                <div ref={submitRef} style={{ width: '100%' }} />
            </EmployeeDialogActions>
        ),
        contract: (
            <EmployeeDialogActions>
                <div ref={submitRef} style={{ width: '100%' }} />
            </EmployeeDialogActions>
        ),
        groups: (
            <EmployeeDialogActions>
                <div ref={submitRef} style={{ width: '100%' }} />
            </EmployeeDialogActions>
        ),
    };

    const handleDelete = () => {
        if (employeeId) {
            dispatch(archiveEmployee({ id: employeeId })).then(() => {
                onClose();
            });
        }
    };

    return (
        <TabDialog
            data-test-id="EmployeeDialog"
            title={
                !contactId
                    ? t('components.EmployeeDialog.addNewContact')
                    : `${t('components.EmployeeDialog.editContact')}: ${
                          currentContact ? currentContact.display_name : ''
                      }`
            }
            initTab={initTab}
            tabs={tabs}
            actions={tabActions}
            loading={loading}
            onDelete={handleDelete}
            open={open}
            onClose={onClose}
            onExited={onExited}
        />
    );
};

EmployeeDialog.propTypes = {
    employeeId: PropTypes.number,
    open: PropTypes.bool.isRequired,
    initTab: PropTypes.string,
    onDone: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    onExited: PropTypes.func.isRequired,
};

EmployeeDialog.defaultProps = {
    employeeId: null,
    initTab: '',
    onDone: null,
};

export default EmployeeDialog;
