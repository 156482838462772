import React, { useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Form from '../form/formik/Form';
import { IdPropType, RefPropType } from '../../modules/proptypes';
import { useTranslation } from 'react-i18next';
import SubmitButton from '../form/formik/SubmitButton';
import { SubjectPropType } from '../../modules/abilities/proptypes';
import { CONTRACT_RESOURCE } from '../../modules/api/resources';
import { selectContractById } from '../../modules/contracts/selectors';
import { storeContract, updateContract } from '../../modules/contracts/actions';
import {
    contractSchema,
    getContractInitialValues,
    getInitialValuesFromContract,
} from '../../modules/contracts/schema';
import ContractFormBody from './ContractFormBody';
import { formatContractSubmit } from '../../modules/contracts/utils';

const ContractForm = ({
    contractId,
    employeeId,
    onLoading,
    initialValues: initialValuesProps,
    onSubmit,
    subject,
    validationSchema,
    actionsComponent,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const contract = useSelector(state => selectContractById(state, contractId));

    const initialValues = useMemo(
        () => ({
            ...((contract ? getInitialValuesFromContract(contract) : null) ||
                initialValuesProps ||
                getContractInitialValues()),
            employee_id: employeeId,
        }),
        [contract, employeeId, initialValuesProps]
    );

    const handleSubmit = newValue => {
        setLoading(true);
        if (onLoading) onLoading(true);
        return dispatch(
            contractId
                ? updateContract(formatContractSubmit(newValue))
                : storeContract(formatContractSubmit(newValue))
        ).finally(() => {
            setLoading(false);
            if (onLoading) onLoading(false);
        });
    };

    return (
        <Form
            initialValues={initialValues}
            subject={subject}
            validationSchema={validationSchema || contractSchema}
            onSubmit={onSubmit || handleSubmit}
        >
            <ContractFormBody />
            <Grid container spacing={2} justifyContent="flex-end">
                {actionsComponent ? (
                    actionsComponent
                ) : (
                    <SubmitButton>{t('form.SubmitButton.defaultLabel')}</SubmitButton>
                )}
            </Grid>
        </Form>
    );
};

ContractForm.propTypes = {
    contractId: IdPropType,
    employeeId: IdPropType,
    onLoading: PropTypes.func,
    initialValues: PropTypes.shape({}),
    onSubmit: PropTypes.func,
    subject: SubjectPropType,
    validationSchema: PropTypes.shape({}),
    actionsComponent: PropTypes.node,
};

ContractForm.defaultProps = {
    contractId: null,
    employeeId: null,
    onLoading: null,
    initialValues: null,
    onSubmit: null,
    subject: CONTRACT_RESOURCE,
    validationSchema: null,
    actionsComponent: null,
};

export default ContractForm;
