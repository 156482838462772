import React from 'react';
import PropTypes from 'prop-types';
import { useFieldFast } from '../../../modules/form/hooks';
import { getFormFieldError } from '../../../modules/loading/utils';
import { ErrorPropType } from '../../../modules/loading/proptypes';
import BaseCheckbox from '../base/BaseCheckbox';
import { useTranslation } from 'react-i18next';

const Checkbox = ({
    name,
    originalName,
    label,
    onChange,
    formatValue,
    errors,
    disabled,
    contrast,
    size,
    I,
    ...other
}) => {
    const { t } = useTranslation();
    const [field, meta, helpers] = useFieldFast(name);
    const error = getFormFieldError(name, errors, meta);

    const handleChange = (event, newValue) => {
        const value =
            typeof formatValue === 'function' ? formatValue(newValue, field.value) : newValue;

        helpers.setValue(value);
        if (onChange) {
            onChange(name, value);
        }
    };

    return (
        <BaseCheckbox
            name={name}
            originalName={originalName}
            label={label}
            value={field.value || false}
            error={error ? t(`errors.${error.split('.').join('')}`) : null}
            onChange={handleChange}
            disabled={disabled}
            contrast={contrast}
            size={size}
            I={I}
            {...other}
        />
    );
};

Checkbox.propTypes = {
    name: PropTypes.string.isRequired,
    originalName: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onChange: PropTypes.func,
    formatValue: PropTypes.func,
    errors: ErrorPropType,
    disabled: PropTypes.bool,
    contrast: PropTypes.bool,
    size: PropTypes.string,
    I: PropTypes.string,
};

Checkbox.defaultProps = {
    originalName: null,
    label: null,
    onChange: null,
    formatValue: null,
    errors: null,
    disabled: false,
    contrast: false,
    size: 'default',
    I: null,
};

export default Checkbox;
