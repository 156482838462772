import { verifiedDurationInt } from '../datetime/utils';
import { WORK_DURATION_DAYS } from './schema';

export const formatContractSubmit = (contract, removeEmpty) => {
    if (removeEmpty) {
        // Create a new contract object by filtering out properties with undefined values
        const filteredContract = Object.keys(contract).reduce((carry, key) => {
            if (contract[key] !== undefined) {
                carry[key] = contract[key];
            }
            return carry;
        }, {});

        return filteredContract;
    }

    // assemble all days work durations to an array `work_duration`
    const workDuration = WORK_DURATION_DAYS.map(day =>
        verifiedDurationInt(contract[`work_duration_${day}`], true)
    );

    // Remove all the `work_duration_...` properties
    const newContract = { ...contract };
    WORK_DURATION_DAYS.forEach(day => delete newContract[`work_duration_${day}`]);

    return {
        ...newContract,
        work_duration: workDuration,
    };
};
