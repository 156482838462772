import { createResourceSlice } from '../redux/resource/createResourceSlice';
import { WORKTIME_SUMMARY_RESOURCE } from '../api/resources';

const worktimeSummarySlice = createResourceSlice({
    resource: WORKTIME_SUMMARY_RESOURCE,
    byKey: '',
});

export const {
    indexFulfilled,
    showFulfilled,
    storeFulfilled,
    updateFulfilled,
    destroyFulfilled,
    indexWorktimeSummarys,
    showWorktimeSummary,
    storeWorktimeSummary,
    updateWorktimeSummary,
    archiveWorktimeSummary,
    restoreWorktimeSummary,
    destroyWorktimeSummary,
} = worktimeSummarySlice.actions;

export const {
    selectWorktimeSummariesById,
    selectWorktimeSummaryById,
    makeWorktimeSummarysByIdsSelector,
    selectAllWorktimeSummaryIds,
    selectAllWorktimeSummarys,
    selectWorktimeSummarysLoading,
    selectWorktimeSummarysInitialized,
    selectWorktimeSummaryLoading,
    selectWorktimeSummaryInitialized,
    selectWorktimeSummaryIdsBy,
    makeWorktimeSummarysBySelector,
    selectWorktimeSummaryLoadingBy,
    selectWorktimeSummaryInitializedBy,
} = worktimeSummarySlice.selectors;

export const { useWorktimeSummarys, useWorktimeSummary, useWorktimeSummarysBy } =
    worktimeSummarySlice.hooks;

export default worktimeSummarySlice.reducer;
