import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IdPropType } from '../../../modules/proptypes';
import { destroyContract, updateContract } from '../../../modules/contracts/actions';
import {
    contractSchema,
    getInitialValuesFromContract,
    WORK_DURATION_DAYS,
} from '../../../modules/contracts/schema';
import { CONTRACT_RESOURCE } from '../../../modules/api/resources';
import ResourceTable from '../../resourceTable/ResourceTable';
import { COLUMN_TYPES } from '../../../modules/resourceTable/columnTypes';
import Duration from '../../utils/Duration';
import DurationInput from '../../form/formik/DurationInput';
import CreateResourceButton from '../../table/resource/CreateResourceButton';
import { showDialog } from '../../../modules/dialogs/dialogSlice';
import { useCallbackFunc } from '../../../modules/hooks';
import { CONTRACT_DIALOG } from '../../dialogs/dialogs';
import { selectFirstLastListData } from '../../../modules/lists/selectors';
import { formatContractSubmit } from '../../../modules/contracts/utils';
import { formatDurationText } from '../../../modules/datetime/utils';
import ResourceTablePrint from '../../resourceTable/ResourceTablePrint';
import PrintIconButton from '../../buttons/PrintIconButton';
import { selectEmployeeById } from '../../../modules/employees/employeesSlice';
import { TotalWorkDuration } from '../../contracts/ContractFormBody';

const getWorkDurationColumns = () =>
    WORK_DURATION_DAYS.map((day, index) => ({
        id: day,
        accessorKey: `work_duration`,
        type: COLUMN_TYPES.DURATION,
        Cell: ({ renderedCellValue: durations }) => {
            const durationValue = durations && Array.isArray(durations) ? durations[index] : null;
            return durationValue ? <Duration durationValue={durationValue} asDecimal /> : null;
        },
        Edit: ({ column }) => {
            const columnProps = column.columnDef;
            const name = `work_duration_${columnProps.id}`;

            return <DurationInput name={name} label={columnProps.header} size="small" fullWidth />;
        },
        convertString: (value, row) =>
            row ? formatDurationText(row?.work_duration[index] || row.duration, true) : null,
    }));

const COLUMNS = [
    {
        id: 'personnelNumber',
        accessorKey: 'personnel_number',
    },
    {
        id: 'startedAt',
        accessorKey: 'started_at',
        type: COLUMN_TYPES.DATE,
    },
    {
        id: 'endedAt',
        accessorKey: 'ended_at',
        type: COLUMN_TYPES.DATE,
    },
    ...getWorkDurationColumns(),
    {
        id: 'sum',
        accessorKey: 'work_duration',
        type: COLUMN_TYPES.DURATION,
        Cell: ({ renderedCellValue: durations }) => {
            const sumDurationValue = durations.reduce((acc, curr) => acc + curr, 0);
            return sumDurationValue ? (
                <Duration durationValue={sumDurationValue} asDecimal />
            ) : null;
        },
        Edit: ({ column }) => <TotalWorkDuration variant="inherit" />,
        forcePrintString: true,
        convertString: (value, row) =>
            row
                ? formatDurationText(
                      row?.work_duration.reduce((acc, curr) => acc + curr, 0),
                      true
                  )
                : null,
    },
    {
        id: 'vacationDays',
        accessorKey: 'vacation_days',
        type: COLUMN_TYPES.NUMBER,
    },
    {
        id: 'cancellationPeriod',
        accessorKey: 'cancellation_period',
        type: COLUMN_TYPES.NUMBER,
        size: 160,
    },
    {
        accessorKey: 'costs',
        type: COLUMN_TYPES.MONEY,
    },
    {
        accessorKey: 'description',
        type: COLUMN_TYPES.TEXT_MULTI,
    },
    {
        id: 'createdAt',
        accessorKey: 'created_at',
        type: COLUMN_TYPES.DATETIME,
        readOnly: true,
    },
    {
        id: 'updatedAt',
        accessorKey: 'updated_at',
        type: COLUMN_TYPES.DATETIME,
        readOnly: true,
    },
];

const EmployeeContract = ({ employeeId, contractId, submitRef }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const employee = useSelector(state => selectEmployeeById(state, employeeId));

    const listId = useMemo(() => `EmployeeContractTable.${employeeId}`, [employeeId]);

    const recentContract = useSelector(state => selectFirstLastListData(state, listId, true, true));

    const fetchParams = useMemo(
        () => (employeeId ? { employee_id: employeeId } : undefined),
        [employeeId]
    );

    const handleDialog = (employeeContractIds = [], initialValues = null) => {
        dispatch(
            showDialog({
                type: CONTRACT_DIALOG,
                props: {
                    contractIds: employeeContractIds,
                    create: !employeeContractIds.length,
                    initialValues,
                    employeeId,
                },
            })
        );
    };

    const handleSubmitRow = employeeContract => {
        if (typeof employeeContract === 'object') {
            return dispatch(updateContract(formatContractSubmit(employeeContract)));
        }

        return null;
    };

    const handleDelete = id => dispatch(destroyContract({ id }));

    const stableCheck = useCallbackFunc(item => item.employee_id === employeeId);

    const stableCompare = useCallbackFunc((old, current) => 1);

    const renderPrintHead = () => {
        if (employee) {
            return (
                <>
                    <h3>{employee.person?.display_name || employee.user?.display_name}</h3>
                    <p>{employee.user?.email}</p>
                </>
            );
        }

        return null;
    };

    const renderTopToolbarActions = ({
        table,
        columns: fullColumns,
        aggregateTotal,
        grouping,
        isLoading = false,
    }) => (
        <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
            alignSelf="center"
        >
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <CreateResourceButton
                    listId={listId}
                    resource={CONTRACT_RESOURCE}
                    onClick={() =>
                        handleDialog(
                            [],
                            recentContract
                                ? {
                                      ...getInitialValuesFromContract(recentContract),
                                      started_at: new Date(),
                                  }
                                : null
                        )
                    }
                />
            </Grid>
            <Grid item>
                <PrintIconButton
                    subject={CONTRACT_RESOURCE}
                    label={t('components.Print.currentPage')}
                    title={t('components.ResourceTable.EmployeeContractTable.label')}
                    disabled={isLoading}
                >
                    <ResourceTablePrint
                        table={table}
                        columns={fullColumns}
                        label={t('components.ResourceTable.EmployeeContractTable.label')}
                        ignoredColumns={['total']}
                        grouping={grouping}
                        aggregateTotal={aggregateTotal}
                        renderHead={renderPrintHead}
                    />
                </PrintIconButton>
            </Grid>
        </Grid>
    );

    return (
        <ResourceTable
            listId={listId}
            genericListId="EmployeeContractTable"
            label={t('components.ResourceTable.EmployeeContractTable.label')}
            resource={CONTRACT_RESOURCE}
            columns={COLUMNS}
            initialColumnVisibility={{
                createdAt: false,
                updatedAt: false,
            }}
            fetchParams={fetchParams}
            initialSorting={[{ id: 'startedAt', desc: true }]}
            criteria={{
                check: stableCheck,
                compare: stableCompare,
            }}
            topToolbarCustomActions={renderTopToolbarActions}
            inlineEdit={{
                validationSchema: contractSchema,
                formatInitialValues: editContract => {
                    if (!editContract) return editContract;
                    const formattedEditontract = getInitialValuesFromContract(editContract);
                    if (formattedEditontract?.work_duration !== undefined) {
                        delete formattedEditontract.work_duration;
                    }
                    return formattedEditontract;
                },
            }}
            index
            autoload
            exportCsv
            stickyColumnActions
            disableSortingChange
            enableColumnDragging={false}
            onSubmit={handleSubmitRow}
            onDelete={handleDelete}
        />
    );
};

EmployeeContract.propTypes = {
    employeeId: IdPropType,
    updatedContact: PropTypes.shape({
        person_id: IdPropType,
        company_id: IdPropType,
    }),
    onChange: PropTypes.func,
    submitRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
};

EmployeeContract.defaultProps = {
    updatedContact: null,
    employeeId: null,
    onChange: null,
};

export default EmployeeContract;
